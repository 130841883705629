import React,{useState} from 'react'
import { useAuth } from '../AuthContext';
import { useLocation,useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios'
import { baseUrl } from '../Utils/app';
import { Link } from 'react-router-dom';


export default function Register() {
    const { state, dispatch } = useAuth();
    const location = useLocation()
    
    const [formControls,setFormControls] = useState({
        'email':'',
        'password':'',
        'type':"admin"
    });

    const [loading,setLoading] = useState(false);
    let loadingToast: any;

    const navigate = useNavigate();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        let postData = {
            ...formControls,
        }
        setLoading(true)

        await axios.post(`${baseUrl}/api/admin-register`,postData).then((response) =>{
            console.log(response)
            setLoading(false)
            toast.dismiss(loadingToast)
            setFormControls({
                'email':'',
                'password':'',
                "type": "admin"
            })
            if(response?.data?.data){
                 navigate('/users')
            }
            
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
            setLoading(false)
        });
    }

  return (
    <div className="relative bg-cover bg-center " style={{backgroundImage: "url(assets/images/bg-auth3.png)"}}>
        <div className="absolute inset-0 dark:bg-black/80"></div>
        <div className="relative flex flex-col items-center justify-start py-20 h-screen">

            <div className="pb-10">

                <p className="text-gray-400 mt-3 dark:text-gray-300 text-4xl font-bold">Create User</p>
            </div> 

            <div className="flex justify-center">
                <div className="w-full min-w-full max-w-full px-4 mx-auto">

                    <div className="card overflow-hidden">
                        <div className="p-9">

                            <div className="text-center mx-auto w-3/4">
                                <h4 className="text-center text-lg uppercase font-bold mb-6">Enter User Details</h4>
                            </div>

                            <form onSubmit={(e)=> handleSubmit(e)} className="sm:w-80 w-full">

                                <div className="mb-6 space-y-2">
                                    <label htmlFor="emailaddress" className="font-medium text-gray-500">Email address</label>
                                    <input className="form-input placeholder:text-gray-400 text-gray-400" type="email" id="emailaddress" required value={formControls.email} onChange={(e) => setFormControls({...formControls, 'email':e.target.value})} placeholder="Enter your email"/>
                                </div>

                                <div className="mb-6 space-y-2">
                                    <label htmlFor="password" className="font-medium text-gray-500">Password</label>
                                    <input required value={formControls.password} onChange={(e) => setFormControls({...formControls, 'password':e.target.value})} type="password" id="password" className="form-input placeholder:text-gray-400 text-gray-400" placeholder="Enter your password"/>
                                </div>

                                {/* <div className="mb-6">
                                    <div className="flex items-center">
                                        <input type="checkbox" className="form-checkbox rounded text-primary" id="checkbox-signin"/>
                                        <label className="ms-2 font-medium text-gray-500" htmlFor="checkbox-signin">I accept <a href="#" className="font-medium text-gray-700">Terms and Conditions</a></label>
                                    </div>
                                </div> */}

                                <div className="text-center mb-4">
                                    <button className="btn w-full rounded bg-green-500 bg-primary text-white" type="submit"> Create User </button>
                                </div>
                            </form>

                        </div>
                    </div>

                    <div className="text-center mt-8">
                        {/* <p className="text-gray-400 dark:text-gray-300"> Already have account? <Link to={'/login'} className="ms-1 text-gray-800 dark:text-gray-100"><b>Sign In</b></Link></p> */}
                    </div>

                </div>
            </div>

        </div> 
    </div>
  )
}
