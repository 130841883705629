import React from 'react'

interface NavProps{
    pageTitle: string
}

export default function Nav({pageTitle}: NavProps) {
  return (
    <header className="app-header">
        <div className="flex items-center px-6 gap-3">
            

            <div className="me-auto">
                <div className="md:flex hidden">
                    <h4 className="page-title text-lg">{pageTitle}</h4>
                </div>
            </div>

            <div className="md:flex hidden items-center relative">
                <div className="absolute inset-y-0 end-0 flex items-center pe-3 pointer-events-none">
                    <i className="mdi mdi-magnify text-base opacity-60 -z-10"></i>
                </div>
                <input type="search" className="form-input pe-8 ps-4 rounded-full bg-gray-500/10 border-transparent focus:border-transparent placeholder:opacity-60" placeholder="Search..."/>
            </div>

            <div className="flex">
                <button id="light-dark-mode" type="button" className="nav-link p-2">
                    <span className="sr-only">Light/Dark Mode</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="moon" className="lucide lucide-moon block dark:block"><path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="sun" className="lucide lucide-sun hidden dark:hidden"><circle cx="12" cy="12" r="4"></circle><path d="M12 2v2"></path><path d="M12 20v2"></path><path d="m4.93 4.93 1.41 1.41"></path><path d="m17.66 17.66 1.41 1.41"></path><path d="M2 12h2"></path><path d="M20 12h2"></path><path d="m6.34 17.66-1.41 1.41"></path><path d="m19.07 4.93-1.41 1.41"></path></svg>
                </button>
            </div>

            <div className="relative md:flex hidden">
                <button data-fc-type="dropdown" data-fc-placement="bottom-end" type="button" className="nav-link p-2">
                    <span className="sr-only">View notifications</span>
                    <span className="flex items-center justify-center h-6 w-6">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="bell" className="lucide lucide-bell"><path d="M6 8a6 6 0 0 1 12 0c0 7 3 9 3 9H3s3-2 3-9"></path><path d="M10.3 21a1.94 1.94 0 0 0 3.4 0"></path></svg>
                        <span className="absolute top-3 end-1.5 w-4 h-4 flex items-center justify-center rounded-full bg-danger text-white  font-medium text-[10px]">9</span>
                    </span>
                </button>
                <div className="fc-dropdown fc-dropdown-open:opacity-100 hidden opacity-0 w-80 z-50 transition-[margin,opacity] duration-300 bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 rounded-lg">

                    <div className="p-4">
                        <div className="flex items-center justify-between">
                            <h6 className="text-sm"> Notification</h6>
                            <a href="javascript: void(0);" className="text-gray-500">
                                <small>Clear All</small>
                            </a>
                        </div>
                    </div>

                    <div className="p-4 h-80" data-simplebar>
                        <h5 className="text-xs text-gray-500 dark:text-gray-300 mb-2">Today</h5>

                        <a href="javascript:void(0);" className="block mb-4">
                            <div className="card-body">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <div className="flex justify-center items-center h-9 w-9 rounded-full bg text-white bg-primary">
                                            <i className="mdi mdi-comment-account-outline text-lg"></i>
                                        </div>
                                    </div>
                                    <div className="flex-grow truncate ms-2">
                                        <h5 className="text-sm font-semibold mb-1">Datacorp <small className="font-normal text-gray-500 ms-1">1 min ago</small></h5>
                                        <small className="noti-item-subtitle text-muted">Caleb Flakelar commented on Admin</small>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0);" className="block mb-4">
                            <div className="card-body">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <div className="flex justify-center items-center h-9 w-9 rounded-full bg-info text-white">
                                            <i className="mdi mdi-heart text-lg"></i>
                                        </div>
                                    </div>
                                    <div className="flex-grow truncate ms-2">
                                        <h5 className="text-sm font-semibold mb-1">Admin <small className="font-normal text-gray-500 ms-1">1 hr ago</small></h5>
                                        <small className="noti-item-subtitle text-muted">New user registered</small>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0);" className="block mb-4">
                            <div className="card-body">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img src="assets/images/users/avatar-2.jpg" className="rounded-full h-9 w-9" alt="avatar-2.jpg"/>
                                    </div>
                                    <div className="flex-grow truncate ms-2">
                                        <h5 className="text-sm font-semibold mb-1">Cristina Pride <small className="font-normal text-gray-500 ms-1">1 day ago</small></h5>
                                        <small className="noti-item-subtitle text-muted">Hi, How are you? What about our next meeting</small>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <h5 className="text-xs text-gray-500 mb-2">Yesterday</h5>

                        <a href="javascript:void(0);" className="block mb-4">
                            <div className="card-body">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <div className="flex justify-center items-center h-9 w-9 rounded-full bg-primary text-white">
                                            <i className="mdi mdi-account-plus text-lg"></i>
                                        </div>
                                    </div>
                                    <div className="flex-grow truncate ms-2">
                                        <h5 className="text-sm font-semibold mb-1">Datacorp</h5>
                                        <small className="noti-item-subtitle text-muted">Caleb Flakelar commented on Admin</small>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="javascript:void(0);" className="block">
                            <div className="card-body">
                                <div className="flex items-center">
                                    <div className="flex-shrink-0">
                                        <img src="assets/images/users/avatar-4.jpg" className="rounded-full h-9 w-9" alt="avatar-4.jpg"/>
                                    </div>
                                    <div className="flex-grow truncate ms-2">
                                        <h5 className="text-sm font-semibold mb-1">Karen Robinson</h5>
                                        <small className="noti-item-subtitle text-muted">Wow ! this admin looks good and awesome design</small>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                    <a href="javascript:void(0);" className="p-2 border-t border-dashed border-gray-200 dark:border-gray-700 block text-center text-primary underline font-semibold">
                        View All
                    </a>
                </div>
            </div>

            <div className="relative">
                <button data-fc-type="dropdown" data-fc-placement="bottom-end" type="button" className="nav-link flex items-center">
                    <img src="assets/images/users/user-1.jpg" alt="user-1.jpg" className="rounded-full h-8 w-8"/>
                    <span className="text-sm mx-2">Nowak</span>
                    <i className="mdi mdi-chevron-down"></i>
                </button>
                <div className="fc-dropdown fc-dropdown-open:opacity-100 hidden opacity-0 w-44 z-50 transition-[margin,opacity] duration-300 bg-white shadow-lg border rounded py-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800">
                    <h6 className="py-2 px-5">Welcome !</h6>

                    <a  className="flex items-center py-2 px-5 text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="pages-gallery.html">
                        <i data-lucide="user" className="w-4 h-4 me-2"></i>
                        <span>My Account</span>
                    </a>
                    <a  className="flex items-center py-2 px-5 text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="apps-kanban.html">
                        <i data-lucide="settings" className="w-4 h-4 me-2"></i>
                        <span>Settings</span>
                    </a>
                    <a  className="flex items-center py-2 px-5 text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="auth-login.html">
                        <i data-lucide="lock" className="w-4 h-4 me-2"></i>
                        <span>Lock Screen</span>
                    </a>
                    <hr className="my-2 -mx-2 border-gray-200 dark:border-gray-700"/>
                    <a className="flex items-center py-2 px-5 text-sm text-gray-800 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="auth-login.html">
                        <i data-lucide="log-out" className="w-4 h-4 me-2"></i>
                        <span>Logout</span>
                    </a>
                </div>
            </div>

            <div className="flex">
                <button type="button" className="nav-link p-2" data-fc-type="offcanvas" data-fc-target="theme-customization" data-fc-scroll="true" >
                    <span className="sr-only">Customization Button</span>
                    <span className="flex items-center justify-center h-6 w-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="settings" className="lucide lucide-settings"><path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                    </span>
                </button>
            </div>
        </div>
    </header>
  )
}
