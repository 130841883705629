import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';

export default function IndexForm() {
    const scn1_bg_image_ref = useRef<any>();
    const scn1_left_image_ref = useRef<any>();
    const scn2_bg_image_ref = useRef<any>();
    const scn2_item1_image_ref = useRef<any>();
    const scn2_item2_image_ref = useRef<any>();
    const scn2_item3_image_ref = useRef<any>();
    const scn2_item1_modal_gif_ref = useRef<any>();
    const scn2_item2_modal_gif_ref = useRef<any>();
    const scn2_item3_modal_gif_ref = useRef<any>();

    const [formControls,setFormControls] = useState({
        'scn1_bg_image':'',
        'scn1_left_image':'',
        'scn1_title':'',
        'scn1_description':'',
        'scn1_button_text':'',
        'scn2_bg_image':'',
        'scn2_item1_image':'',
        'scn2_item1_title':'',
        'scn2_item1_description':'',
        'scn2_item1_button_text':'',
        'scn2_item1_modal_gif':'',
        'scn2_item2_image':'',
        'scn2_item2_title':'',
        'scn2_item2_description':"",
        'scn2_item2_button_text':'',
        'scn2_item2_modal_gif':'',
        'scn2_item3_image':'',
        'scn2_item3_title':'',
        'scn2_item3_description':'',
        'scn2_item3_button_text':'',
        'scn2_item3_modal_gif':'',

    });
    const [loading,setLoading] = useState(false);
    const [getLoading,setGetLoading] = useState(true);

    let loadingToast : any;

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    useEffect(()=> {
        const getIndex = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/getIndex`).then((response) =>{
                console.log(response?.data)
                setGetLoading(false)
                toast.dismiss(loadingToast)
                setFormControls(response?.data?.data)                

            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getIndex()
    },[])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        loadingToast = toast.loading("Loading");

        let scn1_bg_image_url : any ='';
        let scn1_left_image_url : any ='';
        let scn2_bg_image_url : any='';
        let scn2_item1_image_url : any ='';
        let scn2_item2_image_url : any ='';
        let scn2_item3_image_url : any='';
        let scn2_item1_modal_gif_url : any ='';
        let scn2_item2_modal_gif_url : any ='';
        let scn2_item3_modal_gif_url : any='';

        Promise.all([
            fileUploadPromise((scn1_bg_image_ref.current && scn1_bg_image_ref.current.files) ? scn1_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn1_left_image_ref.current && scn1_left_image_ref.current.files) ? scn1_left_image_ref.current.files[0]: null),
            fileUploadPromise((scn2_bg_image_ref.current && scn2_bg_image_ref.current.files) ? scn2_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn2_item1_image_ref.current && scn2_item1_image_ref.current.files) ? scn2_item1_image_ref.current.files[0]: null),
            fileUploadPromise((scn2_item2_image_ref.current && scn2_item2_image_ref.current.files) ? scn2_item2_image_ref.current.files[0]: null),
            fileUploadPromise((scn2_item3_image_ref.current && scn2_item3_image_ref.current.files) ? scn2_item3_image_ref.current.files[0]: null),
            fileUploadPromise((scn2_item1_modal_gif_ref.current && scn2_item1_modal_gif_ref.current.files) ? scn2_item1_modal_gif_ref.current.files[0]: null),
            fileUploadPromise((scn2_item2_modal_gif_ref.current && scn2_item2_modal_gif_ref.current.files) ? scn2_item2_modal_gif_ref.current.files[0]: null),
            fileUploadPromise((scn2_item3_modal_gif_ref.current && scn2_item3_modal_gif_ref.current.files) ? scn2_item3_modal_gif_ref.current.files[0]: null),
        ])
            .then(async (results) => {
                console.log(results)
                
                results[0] != ""? scn1_bg_image_url = results[0] : scn1_bg_image_url = formControls?.scn1_bg_image
                results[1] != ""? scn1_left_image_url = results[1] : scn1_left_image_url = formControls?.scn1_left_image
                results[2] != ""? scn2_bg_image_url = results[2] : scn2_bg_image_url = formControls?.scn2_bg_image
                results[3] != ""? scn2_item1_image_url = results[3] : scn2_item1_image_url = formControls?.scn2_item1_image
                results[4] != ""? scn2_item2_image_url = results[4] : scn2_item2_image_url = formControls?.scn2_item2_image
                results[5] != ""? scn2_item3_image_url = results[5] : scn2_item3_image_url = formControls?.scn2_item3_image
                results[6] != ""? scn2_item1_modal_gif_url = results[6] : scn2_item1_modal_gif_url = formControls?.scn2_item1_modal_gif
                results[7] != ""? scn2_item2_modal_gif_url = results[7] : scn2_item2_modal_gif_url = formControls?.scn2_item2_modal_gif
                results[8] != ""? scn2_item3_modal_gif_url = results[8] : scn2_item3_modal_gif_url = formControls?.scn2_item3_modal_gif

                let postData = {
                    ...formControls,
                    'scn1_bg_image':scn1_bg_image_url,
                    'scn1_left_image':scn1_left_image_url,
                    'scn2_bg_image':scn2_bg_image_url,
                    'scn2_item1_image':scn2_item1_image_url,
                    'scn2_item2_image':scn2_item2_image_url,
                    'scn2_item3_image':scn2_item3_image_url,
                    'scn2_item1_modal_gif':scn2_item1_modal_gif_url,
                    'scn2_item2_modal_gif':scn2_item2_modal_gif_url,
                    'scn2_item3_modal_gif':scn2_item3_modal_gif_url
                }

                axios.defaults.withCredentials = true;
                await axios.post(`${baseUrl}/api/saveIndex`,postData).then((response) =>{
                    console.log(response)
                    setLoading(false)
                    setFormControls(response?.data?.data)  
                    toast.dismiss(loadingToast)
                    toast.success("Media updated Successfully");
                    // setFormControls({
                    //     'scn1_bg_image':'',
                    //     'scn1_left_image':'',
                    //     'scn1_title':'',
                    //     'scn1_description':'',
                    //     'scn2_bg_image':'',
                    //     'scn2_item1_image':'',
                    //     'scn2_item1_title':'',
                    //     'scn2_item1_description':'',
                    //     'scn2_item2_image':'',
                    //     'scn2_item2_title':'',
                    //     'scn2_item2_description':"",
                    //     'scn2_item3_image':'',
                    //     'scn2_item3_title':'',
                    //     'scn2_item3_description':'',
                    // })
                }).catch((error)=>{
                    console.log(error)
                    toast.dismiss(loadingToast)
                    toast.error(error?.message);
                    setLoading(false)
                });

            }).catch((e)=>{
                console.log(e)
                toast.error(e?.message);
            })
    }

    const fileUploadPromise = (imageFile:File|undefined|null)  => {
        return new Promise (async (resolve, reject) => {
            if(imageFile){
                    let uuid = require("uuid");
                    try{
                        let uploadTask = storage.ref(`/fileUploads/${uuid.v4()+"."+imageFile.name.split(".")[1]}`).put(imageFile);
                        uploadTask.then(uploadSnapshot => {
                            return uploadSnapshot.ref.getDownloadURL();
                        }).then(url => {
                            resolve(url);
                        }).catch(err => {
                            reject(err);
                        })
                    }catch(e){
                        console.log(e)
                        reject(e);

                    }
            }else{
                resolve("");
            }
        })
    }
   
    return (
        <>
            <Nav pageTitle="Edit Index Screen"></Nav>
            <main className="p-6">
                <form onSubmit={(e)=>handleSubmit(e)} className="flex flex-col gap-6">
                    <div className="card">
                        <div className="p-6">
                            <h4 className="card-title mb-4">Section 1</h4>

                            <div className="grid lg:grid-cols-2 gap-6">
                                <div>
                                    <div className="flex flex-col gap-6">
                                        <div>
                                            <label
                                                className="block text-gray-600 mb-2"
                                                htmlFor="simpleinput"
                                            >
                                                Section 1 - Background Image
                                            </label>
                                            {
                                                formControls?.scn1_bg_image && <img src={formControls?.scn1_bg_image} className='w-[200px] block mb-2'/>
                                            }
                                            <input
                                                type="file"
                                                id="example-fileinput"
                                                className="form-input border"
                                                ref={scn1_bg_image_ref}
                                            />
                                        </div>

                                        <div>
                                            <label
                                                className="block text-gray-600 mb-2"
                                                htmlFor="example-email"
                                            >
                                                Section 1 - Left Image
                                            </label>
                                            {
                                                formControls?.scn1_left_image && <img src={formControls?.scn1_left_image} className='w-[200px] block mb-2'/>
                                            }
                                            <input
                                                type="file"
                                                id="example-fileinput"
                                                className="form-input border"
                                                ref={scn1_left_image_ref}
                                            />
                                        </div>

                                        <div>
                                            <label
                                                className="block text-gray-600 mb-2"
                                                htmlFor="example-password"
                                            >
                                                Section 1 - Section Title
                                            </label>
                                            <input
                                                type="text"
                                                id="example-password"
                                                className="form-input"
                                                value={formControls?.scn1_title}
                                                required
                                                onChange={(e)=> setFormControls({...formControls,scn1_title:e.target.value})}
                                            />
                                        </div>

                                        <div>
                                            <label
                                                className="block text-gray-600 mb-2"
                                                htmlFor="example-palaceholder"
                                            >
                                                Section 1 - Section description
                                            </label>
                                            <textarea
                                                className="form-input"
                                                id="example-textarea"
                                                rows={5}
                                                required
                                                value={formControls?.scn1_description}
                                                onChange={(e)=> setFormControls({...formControls,scn1_description:e.target.value})}
                                            ></textarea>
                                        </div>

                                        <div>
                                            <label
                                                className="block text-gray-600 mb-2"
                                                htmlFor="example-password"
                                            >
                                                Section 1 - Button text
                                            </label>
                                            <input
                                                type="text"
                                                id="example-password"
                                                className="form-input"
                                                value={formControls?.scn1_button_text}
                                                required
                                                onChange={(e)=> setFormControls({...formControls,scn1_button_text:e.target.value})}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <h4 className="card-title mb-4">Section 2</h4>

                            <div className="grid lg:grid-cols-1 gap-6">
                                <div>
                                    <div className="flex flex-col gap-6">
                                        <div>
                                            <label
                                                className="block text-gray-600 mb-2"
                                                htmlFor="simpleinput"
                                            >
                                                Section 2 - Background Image
                                            </label>
                                            {
                                                formControls?.scn2_bg_image && <img src={formControls?.scn2_bg_image} className='w-[200px] block mb-2'/>
                                            }
                                            <input
                                                type="file"
                                                id="example-fileinput"
                                                className="form-input border"
                                                ref={scn2_bg_image_ref}
                                            />
                                        </div>

                                        <div className='grid grid-cols-3 gap-6'>
                                            <div>
                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-email"
                                                    >
                                                        Item 1 - Image
                                                    </label>
                                                    {
                                                        formControls?.scn2_item1_image && <img src={formControls?.scn2_item1_image} className='w-[200px] block mb-2'/>
                                                    }
                                                    <input
                                                        type="file"
                                                        id="example-fileinput"
                                                        className="form-input border"
                                                        ref={scn2_item1_image_ref}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-password"
                                                    >
                                                        Item 1 Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="example-password"
                                                        className="form-input"
                                                        value={formControls?.scn2_item1_title}
                                                        required
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item1_title:e.target.value})}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-palaceholder"
                                                    >
                                                        Item 1 description
                                                    </label>
                                                    <textarea
                                                        className="form-input"
                                                        id="example-textarea"
                                                        rows={5}
                                                        required
                                                        value={formControls?.scn2_item1_description}
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item1_description:e.target.value})}
                                                    ></textarea>
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-password"
                                                    >
                                                        Item 1 Button text
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="example-password"
                                                        className="form-input"
                                                        value={formControls?.scn2_item1_button_text}
                                                        required
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item1_button_text:e.target.value})}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-email"
                                                    >
                                                        Item 1 - Modal Gif
                                                    </label>
                                                    {
                                                        formControls?.scn2_item1_modal_gif && <img src={formControls?.scn2_item1_modal_gif} className='w-[200px] block mb-2'/>
                                                    }
                                                    <input
                                                        type="file"
                                                        id="example-fileinput"
                                                        className="form-input border"
                                                        ref={scn2_item1_modal_gif_ref}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-email"
                                                    >
                                                        Item 2 - Image
                                                    </label>
                                                    {
                                                        formControls?.scn2_item2_image && <img src={formControls?.scn2_item2_image} className='w-[200px] block mb-2'/>
                                                    }
                                                    <input
                                                        type="file"
                                                        id="example-fileinput"
                                                        className="form-input border"
                                                        ref={scn2_item2_image_ref}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-password"
                                                    >
                                                        Item 2 Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="example-password"
                                                        className="form-input"
                                                        value={formControls?.scn2_item2_title}
                                                        required
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item2_title:e.target.value})}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-palaceholder"
                                                    >
                                                        Item 2 description
                                                    </label>
                                                    <textarea
                                                        className="form-input"
                                                        id="example-textarea"
                                                        rows={5}
                                                        required
                                                        value={formControls?.scn2_item2_description}
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item2_description:e.target.value})}
                                                    ></textarea>
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-password"
                                                    >
                                                        Item 2 Button text
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="example-password"
                                                        className="form-input"
                                                        value={formControls?.scn2_item2_button_text}
                                                        required
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item2_button_text:e.target.value})}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-email"
                                                    >
                                                        Item 2 - Modal Gif
                                                    </label>
                                                    {
                                                        formControls?.scn2_item2_modal_gif && <img src={formControls?.scn2_item2_modal_gif} className='w-[200px] block mb-2'/>
                                                    }
                                                    <input
                                                        type="file"
                                                        id="example-fileinput"
                                                        className="form-input border"
                                                        ref={scn2_item2_modal_gif_ref}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-email"
                                                    >
                                                        Item 3 - Image
                                                    </label>
                                                    {
                                                        formControls?.scn2_item3_image && <img src={formControls?.scn2_item3_image} className='w-[200px] block mb-2'/>
                                                    }
                                                    <input
                                                        type="file"
                                                        id="example-fileinput"
                                                        className="form-input border"
                                                        ref={scn2_item3_image_ref}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-password"
                                                    >
                                                        Item 3 Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="example-password"
                                                        className="form-input"
                                                        value={formControls?.scn2_item3_title}
                                                        required
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item3_title:e.target.value})}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-palaceholder"
                                                    >
                                                        Item 3 description
                                                    </label>
                                                    <textarea
                                                        className="form-input"
                                                        id="example-textarea"
                                                        rows={5}
                                                        required
                                                        value={formControls?.scn2_item3_description}
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item3_description:e.target.value})}
                                                    ></textarea>
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-password"
                                                    >
                                                        Item 3 Button text
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="example-password"
                                                        className="form-input"
                                                        value={formControls?.scn2_item3_button_text}
                                                        required
                                                        onChange={(e)=> setFormControls({...formControls,scn2_item3_button_text:e.target.value})}
                                                    />
                                                </div>

                                                <div>
                                                    <label
                                                        className="block text-gray-600 mb-2"
                                                        htmlFor="example-email"
                                                    >
                                                        Item 3 - Modal Gif
                                                    </label>
                                                    {
                                                        formControls?.scn2_item3_modal_gif && <img src={formControls?.scn2_item3_modal_gif} className='w-[200px] block mb-2'/>
                                                    }
                                                    <input
                                                        type="file"
                                                        id="example-fileinput"
                                                        className="form-input border"
                                                        ref={scn2_item3_modal_gif_ref}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='col-span-2 '>
                    {
                        !loading?(
                            <button type="submit" className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Submit</button>
                        ):(
                            <button type="button" disabled className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Loading</button>
                        )
                    }
                    </div>  
                </form>
            </main>
        </>
    );
}
