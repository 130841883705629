import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import { baseUrl } from '../Utils/app';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';


interface Item {
    id: number;
    ip_address: string;
    promo_page_id: string;
    visit: string;
    created_at: string;
    updated_at: string;
  }

interface MetricsResponse {
  data: {
    data: any[]; // Define this more specifically based on your actual data structure
  };
}


export default function Metrics() {

    let loadingToast : any;
    const [loading, setLoading] = useState<boolean>(true);
    const [items, setItems] = useState<any>(null);
    const [sortBy, setSortBy] =  useState<string>("allRecords")
    const [searchText, setSearchText] = useState("");
    // const [SortedRecords, setSortedRecords] = useState<(type: any) => number>(() => (type:any) => 0);
    const tableRef = useRef(null);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [musicMetrics, setMusicMetrics] = useState<number>();
    const [gameMetrics, setGameMetrics] = useState<number>();
    const [musicUsage, setMusicUsageMetrics] = useState<any[]>([]);
    const [gameUsage, setGameUsageMetrics] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          const loadingToast = toast.loading("Loading...");
          try {
            const response = await axios.get<MetricsResponse>(`${baseUrl}/api/metrics`, {
              params: { sortBy } // Add sortBy as a query parameter
            });
            setItems(response.data.data);
            toast.dismiss(loadingToast);
          } catch (error: unknown) {
            console.error(error);
            toast.dismiss(loadingToast);
            if (axios.isAxiosError(error)) {
              toast.error(error.message);
            } else {
              toast.error("An unexpected error occurred");
            }
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }, [sortBy]);

    useEffect(()=> {
        const fetchMusicMetrics = async () => {
          setLoading(true);
          const loadingToast = toast.loading("Loading...");
          try {
            const response = await axios.get(`${baseUrl}/api/media_pages_metric`, {});
            setMusicMetrics(response.data.data?.music_visit);
            setGameMetrics(response.data.data?.game_visit);
            setMusicUsageMetrics(response.data.data?.music_time_spent);
            setGameUsageMetrics(response.data.data?.game_time_spent);
            toast.dismiss(loadingToast);
          } catch (error: unknown) {
            console.error(error);
            toast.dismiss(loadingToast);
            if (axios.isAxiosError(error)) {
              toast.error(error.message);
            } else {
              toast.error("An unexpected error occurred");
            }
          } finally {
            setLoading(false);
          }
        };
        fetchMusicMetrics();
    },[])


    let SortedRecords = (type: any) => {
      return items && items.metrics
        ? items.metrics.find((item: any) => item?.promo_page_id?.toLowerCase() === type?.toLowerCase())?.total_visits ?? 0
        : 0;
    };

      const handleExportPDF = () => {
        const input : any = tableRef.current;
    
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            pdf.save('Promo Page Metrics.pdf');
          });
        };


    return (
      <> 
        <Nav pageTitle="Metrics"></Nav>      
  
        <main className="p-6">
  
            <div className="flex flex-col gap-6">

                <div className="flex items-center justify-between mb-6">
                    <div className='flex gap-10 items-center'>
                        <div>
                            <label htmlFor="start-date">Sort By:</label>
                            <select name='status' className="form-select " onChange={e => setSortBy(e.target.value)}>
                                <option value={'all'}>All</option>
                                <option value={'today'}>Today</option>
                                <option value={'yesterday'}>Yesterday</option>
                                <option value={'twoDaysAgo'}>Two Days Ago</option>
                                <option value={'threeDaysAgo'}>Three Days Ago</option>
                                <option value={'thisWeek'}>This Week </option>
                                <option value={'lastWeek'}>Last Week</option>
                                <option value={'lastTwoWeeks'}>Last Two Weeks</option>
                                <option value={'thisMonth'}>This Month</option>
                                <option value={'lastMonth'}>Last Month</option>
                                <option value={'twoMonthsAgo'}>Two Months Ago</option>
                                <option value={'threeMonthsAgo'}>Three Months Ago</option>
                                <option value={'thisYear'}>This Year</option>
                                <option value={'lastYear'}>Last Year</option>
                            </select>
                        </div>

                    </div>
                        
                </div>

                <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">Lp1 Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.track_lp1} </h2>
                                    {/* <p className="text-gray-400 font-normal">Revenue today</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">Register Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.register} </h2>
                                    {/* <p className="text-gray-400 font-normal">Revenue today</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$9.99 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.track_sub_9_99} </h2>
                                    {/* <p className="text-gray-400 font-normal">Revenue today</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$19.99 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.track_sub_19_99} </h2>
                                    {/* <p className="text-gray-400 font-normal">Revenue today</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$29.99 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.track_sub_29_99} </h2>
                                    {/* <p className="text-gray-400 font-normal">Revenue today</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$39.95 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.track_sub_39_99} </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="flex items-center justify-between mb-6">
                        <h3 className="card-title">Recent Metrics</h3>
                </div>

                <div className="flex items-center justify-between mb-6">
                    <div className='flex gap-10 items-center'>
                        <div>
                            <label htmlFor="start-date">Sort By:</label>
                            <select name='status' className="form-select " onChange={e => setSortBy(e.target.value)}>
                                <option value={'all'}>All</option>
                                <option value={'today'}>Today</option>
                                <option value={'yesterday'}>Yesterday</option>
                                <option value={'twoDaysAgo'}>Two Days Ago</option>
                                <option value={'threeDaysAgo'}>Three Days Ago</option>
                                <option value={'thisWeek'}>This Week </option>
                                <option value={'lastWeek'}>Last Week</option>
                                <option value={'lastTwoWeeks'}>Last Two Weeks</option>
                                <option value={'thisMonth'}>This Month</option>
                                <option value={'lastMonth'}>Last Month</option>
                                <option value={'twoMonthsAgo'}>Two Months Ago</option>
                                <option value={'threeMonthsAgo'}>Three Months Ago</option>
                                <option value={'thisYear'}>This Year</option>
                                <option value={'lastYear'}>Last Year</option>
                            </select>
                        </div>
                        <div>   
                            <label htmlFor="search-text">Search</label>
                            <input type="date" id="search-text" onChange={e => setSearchText(e.target.value)} />
                        </div>
                        <div className=' relative'>
                            <button data-fc-target="dropdown-target13" onClick={()=>setShowOptions(!showOptions)} data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                <i className="mdi mdi-dots-vertical text-xl"></i>
                            </button>
                            {
                                showOptions &&  <div id="dropdown-target13" className=" absolute top-10 right-0  bg-white shadow rounded border dark:border-slate-700 fc-dropdown-open:translate-y-0 translate-y-3 origin-center transition-all duration-300 py-2 dark:bg-gray-800 w-[200px]">
                                    <DownloadTableExcel
                                        filename="Promo Page Metrics"
                                        sheet="Users"
                                        currentTableRef={tableRef.current}>
                                        <button className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-stone-100 dark:hover:bg-slate-700 dark:hover:text-gray-200" >
                                            Export to CSV
                                        </button>
                                    </DownloadTableExcel>
                                    
                                    <button onClick={() =>handleExportPDF()} className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" >
                                        Export to PDF
                                    </button>
                                    <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                        Something else
                                    </a>
                                    <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                        Separated link
                                    </a>
                                </div>
                            }
                        
                        </div>

                    </div>
                        
                </div>

                <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">Lp1 Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {SortedRecords('lp1')} </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">Register Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {SortedRecords('register')} </h2>
                                {/* <p className="text-gray-400 font-normal">Revenue today</p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$9.99 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {SortedRecords('Subscribe_9_99')} </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$19.99 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {SortedRecords('Subscribe_19_99')} </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$29.99 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {SortedRecords('Subscribe_29_99')} </h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">$39.95 Subscription Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">

                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {items?.plans?.track_sub_39_99} </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="flex items-center justify-between mb-6">
                        <h3 className="card-title">Media Pages Metrics</h3>
                </div>

                <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">Music Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {musicMetrics} </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-11">
                                <h4 className="card-title">Game Page Visits</h4>

                                <div className="z-10">
                                    <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>

                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div dir="ltr">
                                    {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                                </div>

                                <div className="text-end">
                                    <h2 className="text-3xl font-normal text-gray-800 mb-1"> {gameMetrics} </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid xl:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6">
                    <div className="overflow-x-auto card p-6">
                        <div className="flex items-center justify-between mb-11">
                            <h4 className="card-title">Time spent on Musics</h4>
                        </div>
                        <div className="min-w-full inline-block align-middle">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Music Id</th>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Music</th>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">total Time spent</th></tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {
                                            musicUsage?.map((item,index) => (<tr>

                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">{index+1}</th>
                                                <th className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item?.music}</th>
                                                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.music_name}</td>
                                                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.total_time_spent}</td>
                                                
                                                    </tr>))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="overflow-x-auto card p-6">
                        <div className="flex items-center justify-between mb-11">
                            <h4 className="card-title">Time spent on Games</h4>
                        </div>
                        <div className="min-w-full inline-block align-middle">
                            <div className="overflow-hidden">
                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Game</th>
                                            <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">total Time spent</th></tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                        {
                                            gameUsage?.map((item,index) => (<tr>

                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">{index+1}</th>
                                                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.game_name}</td>
                                                <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.total_time_spent}</td>

                                                    </tr>))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto bg-green-500 invisible">
                    <div className="min-w-full inline-block align-middle">
                        <div className="overflow-hidden">
                            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Lp1 Page Visits</th>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Register Page Visits</th>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">$9.99 Subscription Page Visits</th>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">$19.99 Subscription Page Visits</th>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">$29.99 Subscription Page Visits</th>
                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">All Subscription Page Visits</th>

                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#1</th>
                                    <th className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{SortedRecords('lp1')}</th>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{SortedRecords('register')}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{SortedRecords('Subscribe_9_99')}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{SortedRecords('Subscribe_19_99')}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{SortedRecords('Subscribe_29_99')}</td>
                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{SortedRecords('Subscribe_29_99')+SortedRecords('Subscribe_19_99')+SortedRecords('Subscribe_9_99')}</td>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div> 
  
        </main>
      </>
       
    )
  }