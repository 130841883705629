import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAuth } from '../AuthContext';
import { useNavigate } from "react-router-dom";


export default function SideNav() {
  const { state, dispatch } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch({ type: 'LOGOUT' });
    navigate('/')
  };

  return (
    (state?.isAuth) ? (<div className="app-menu">
      <Link to="/" className="logo-box">
        <div className="logo-light">
          <img
            src="/assets/images/logo.png"
            className="logo-lg w-[150px] !h-[30px]"
            alt="Light logo"
          />
          <img
            src="/assets/images/logo.png"
            className="logo-sm"
            alt="Small logo"
          />
        </div>

        <div className="logo-dark">
          <img
            src="/assets/images/logo.png"
            className="logo-lg w-[150px] !h-[30px]"
            alt="Dark logo"
          />
          <img
            src="/assets/images/logo.png"
            className="logo-sm"
            alt="Small logo"
          />
        </div>
      </Link>

      <div data-simplebar>
        <div className="user-box relative text-center mt-5">
          {/* <img
            src="/assets/images/users/user-1.jpg"
            alt="user-image"
            className="rounded-full h-14 w-14 p-1 border border-gray-300/30 mx-auto mb-3"
          /> */}
          <div>
            <button
              data-fc-type="dropdown"
              data-fc-placement="bottom"
              type="button"
              className="user-name text-[15px] font-medium mb-1.5"
            >
              Admin
            </button>
            <div className="fc-dropdown fc-dropdown-open:opacity-100 hidden opacity-0 w-40 z-50 transition-all duration-300 bg-white shadow-lg border rounded-lg p-2 border-gray-200 dark:border-gray-700 dark:bg-gray-800">
              <a
                className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-primary dark:text-gray-400 hover:text-white dark:hover:text-white"
                href="#"
              >
                <i data-lucide="user" className="w-4 h-4 me-2"></i>
                <span>My Account</span>
              </a>

              <a
                className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-primary dark:text-gray-400 hover:text-white dark:hover:text-white"
                href="#"
              >
                <i data-lucide="settings" className="w-4 h-4 me-2"></i>
                <span>Settings</span>
              </a>

              <a
                className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-primary dark:text-gray-400 hover:text-white dark:hover:text-white"
                href="#"
              >
                <i data-lucide="lock" className="w-4 h-4 me-2"></i>
                <span>Lock Screen</span>
              </a>

              <a
                className="flex items-center py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-primary dark:text-gray-400 hover:text-white dark:hover:text-white"
                href="#"
              >
                <i data-lucide="log-out" className="w-4 h-4 me-2"></i>
                <span>Logout</span>
              </a>
            </div>
          </div>

          <p className="user-name text-sm mb-3">Admin Head</p>

          <div className="flex justify-center gap-2 mb-4">
            <a href="#" className="user-name">
              <i className="mdi mdi-cog text-base"></i>
            </a>

            <a href="#" className="user-name">
              <i className="mdi mdi-power text-base"></i>
            </a>
          </div>
        </div>

        <ul className="menu" data-fc-type="accordion">
          <li className="menu-title">Navigation</li>

          <li className="menu-item">
            <Link to="/" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Dashboard </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/metrics" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text">Pages Metrics </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/campaigns" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text">Campaign Metrics </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/prime-play" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text">Primeplay Records </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/edit-index" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Edit Index Page </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/edit-about" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Edit About Page </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/edit-help" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Edit Help Page </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/edit-policy" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Edit Policy Page </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/users" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Users </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/customers" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Customers </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/payments" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Payments </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/media" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Media </span>
            </Link>
          </li>

          <li className="menu-item">
            <Link to="/media-upload" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Upload Media </span>
            </Link>
          </li>

          <li className="menu-item">
            <button onClick={()=>handleLogout()} className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-view-dashboard-outline"></i>
              </span>
              <span className="menu-text"> Log Out </span>
            </button>
          </li>

          {/* <li className="menu-title">Apps</li>

          <li className="menu-item">
            <a href="apps-calendar.html" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-calendar-blank-outline"></i>
              </span>
              <span className="menu-text"> Calendar </span>
            </a>
          </li>

          <li className="menu-item">
            <a href="apps-chat.html" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-forum-outline"></i>
              </span>
              <span className="menu-text"> Chat </span>
            </a>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-email-outline"></i>
              </span>
              <span className="menu-text"> Email </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="email-inbox.html" className="menu-link">
                  <span className="menu-text">Inbox</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="email-templates.html" className="menu-link">
                  <span className="menu-text">Email Templates</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-clipboard-outline"></i>
              </span>
              <span className="menu-text"> Tasks </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="task-kanban-board.html" className="menu-link">
                  <span className="menu-text">Kanban Board</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="task-details.html" className="menu-link">
                  <span className="menu-text">Details</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a href="apps-projects.html" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-briefcase-variant-outline"></i>
              </span>
              <span className="menu-text"> Projects </span>
            </a>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-book-open-page-variant-outline"></i>
              </span>
              <span className="menu-text"> Contacts </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="contacts-list.html" className="menu-link">
                  <span className="menu-text">Members List</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="contacts-profile.html" className="menu-link">
                  <span className="menu-text">Profile</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-title">Custom</li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-account-multiple-plus-outline"></i>
              </span>
              <span className="menu-text"> Auth Pages </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="auth-login.html" className="menu-link">
                  <span className="menu-text">Log In</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="auth-register.html" className="menu-link">
                  <span className="menu-text">Register</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="auth-recoverpw.html" className="menu-link">
                  <span className="menu-text">Recover Password</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="auth-lock-screen.html" className="menu-link">
                  <span className="menu-text">Lock Screen</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="auth-confirm-mail.html" className="menu-link">
                  <span className="menu-text">Confirm Mail</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="auth-logout.html" className="menu-link">
                  <span className="menu-text">Logout</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-file-multiple-outline"></i>
              </span>
              <span className="menu-text"> Extra Pages </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="pages-starter.html" className="menu-link">
                  <span className="menu-text">Starter</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-pricing.html" className="menu-link">
                  <span className="menu-text">Pricing</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-timeline.html" className="menu-link">
                  <span className="menu-text">Timeline</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-invoice.html" className="menu-link">
                  <span className="menu-text">Invoice</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-faqs.html" className="menu-link">
                  <span className="menu-text">FAQs</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-gallery.html" className="menu-link">
                  <span className="menu-text">Gallery</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-404.html" className="menu-link">
                  <span className="menu-text">Error 404</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-500.html" className="menu-link">
                  <span className="menu-text">Error 500</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-maintenance.html" className="menu-link">
                  <span className="menu-text">Maintenance</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="pages-coming-soon.html" className="menu-link">
                  <span className="menu-text">Coming Soon</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="layouts-horizontal.html"
              className="menu-link"
              target="_blank"
            >
              <span className="menu-icon">
                <i className="mdi mdi-dock-window"></i>
              </span>
              <span className="menu-text"> Horizontal </span>
            </a>
          </li>

          <li className="menu-title">Components</li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-briefcase-outline"></i>
              </span>
              <span className="menu-text"> Base UI </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="ui-buttons.html" className="menu-link">
                  <span className="menu-text">Buttons</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-badges.html" className="menu-link">
                  <span className="menu-text">Badges</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-cards.html" className="menu-link">
                  <span className="menu-text">Cards</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-avatars.html" className="menu-link">
                  <span className="menu-text">Avatars</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-tabs-accordions.html" className="menu-link">
                  <span className="menu-text">Tabs & Accordions</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-modals.html" className="menu-link">
                  <span className="menu-text">Modals</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-progress.html" className="menu-link">
                  <span className="menu-text">Progress</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-notifications.html" className="menu-link">
                  <span className="menu-text">Notifications</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-offcanvas.html" className="menu-link">
                  <span className="menu-text">Offcanvas</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-placeholders.html" className="menu-link">
                  <span className="menu-text">Placeholders</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-spinners.html" className="menu-link">
                  <span className="menu-text">Spinners</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui.images.html" className="menu-link">
                  <span className="menu-text">Images</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-swiper.html" className="menu-link">
                  <span className="menu-text">Swiper</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-list-group.html" className="menu-link">
                  <span className="menu-text">List Group</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-ratio.html" className="menu-link">
                  <span className="menu-text">Embed Video</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-dropdowns.html" className="menu-link">
                  <span className="menu-text">Dropdowns</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-tooltips-popovers.html" className="menu-link">
                  <span className="menu-text">Tooltips & Popovers</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="ui-typography.html" className="menu-link">
                  <span className="menu-text">Typography</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a href="widgets.html" className="menu-link">
              <span className="menu-icon">
                <i className="mdi mdi-gift-outline"></i>
              </span>
              <span className="menu-text"> Widgets </span>
            </a>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-layers-outline"></i>
              </span>
              <span className="menu-text"> Extended UI </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="extended-range-slider.html" className="menu-link">
                  <span className="menu-text">Range Slider</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="extended-sweet-alert.html" className="menu-link">
                  <span className="menu-text">Sweet Alert</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="extended-draggable-cards.html" className="menu-link">
                  <span className="menu-text">Draggable Cards</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="extended-tour.html" className="menu-link">
                  <span className="menu-text">Tour Page</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="extended-treeview.html" className="menu-link">
                  <span className="menu-text">Tree View</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-shield-outline"></i>
              </span>
              <span className="menu-text"> Icons </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="icons-material-design.html" className="menu-link">
                  <span className="menu-text">Material Design</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="icons-material-symbols.html" className="menu-link">
                  <span className="menu-text">Material Symbols</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="icons-lucide.html" className="menu-link">
                  <span className="menu-text">Lucide Icons</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="icons-font-awesome.html" className="menu-link">
                  <span className="menu-text">Font Awesome 5</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-texture"></i>
              </span>
              <span className="menu-text"> Forms </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="forms-elements.html" className="menu-link">
                  <span className="menu-text">General Elements</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="forms-advanced.html" className="menu-link">
                  <span className="menu-text">Advanced</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="forms-validation.html" className="menu-link">
                  <span className="menu-text">Validation</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="forms-wizard.html" className="menu-link">
                  <span className="menu-text">Wizard</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="forms-quilljs-editor.html" className="menu-link">
                  <span className="menu-text">Quilljs Editor</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="forms-picker.html" className="menu-link">
                  <span className="menu-text">Picker</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="forms-file-uploads.html" className="menu-link">
                  <span className="menu-text">File Uploads</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                {" "}
                <i className="mdi mdi-table"></i>
              </span>
              <span className="menu-text"> Tables </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="tables-basic.html" className="menu-link">
                  <span className="menu-text">Basic Tables</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="tables-datatables.html" className="menu-link">
                  <span className="menu-text">Data Tables</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="tables-editable.html" className="menu-link">
                  <span className="menu-text">Editable Tables</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="tables-tablesaw.html" className="menu-link">
                  <span className="menu-text">Tablesaw Tables</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                {" "}
                <i className="mdi mdi-equalizer"></i>
              </span>
              <span className="menu-text"> Apex Charts </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="charts-apex-area.html" className="menu-link">
                  <span className="menu-text">Area</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-bar.html" className="menu-link">
                  <span className="menu-text">Bar</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-bubble.html" className="menu-link">
                  <span className="menu-text">Bubble</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-candlestick.html" className="menu-link">
                  <span className="menu-text">Candlestick</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-column.html" className="menu-link">
                  <span className="menu-text">Column</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-heatmap.html" className="menu-link">
                  <span className="menu-text">Heatmap</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-line.html" className="menu-link">
                  <span className="menu-text">Line</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-mixed.html" className="menu-link">
                  <span className="menu-text">Mixed</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-timeline.html" className="menu-link">
                  <span className="menu-text">Timeline</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-boxplot.html" className="menu-link">
                  <span className="menu-text">Boxplot</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-treemap.html" className="menu-link">
                  <span className="menu-text">Treemap</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-pie.html" className="menu-link">
                  <span className="menu-text">Pie</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-radar.html" className="menu-link">
                  <span className="menu-text">Radar</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-radialbar.html" className="menu-link">
                  <span className="menu-text">Radialbar</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-scatter.html" className="menu-link">
                  <span className="menu-text">Scatter</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-polar-area.html" className="menu-link">
                  <span className="menu-text">Polar Area</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-apex-sparklines.html" className="menu-link">
                  <span className="menu-text">Sparklines</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                {" "}
                <i className="mdi mdi-chart-donut-variant"></i>
              </span>
              <span className="menu-text"> Chartsjs </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="charts-chartjs-area.html" className="menu-link">
                  <span className="menu-text">Area</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-chartjs-bar.html" className="menu-link">
                  <span className="menu-text">Bar</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-chartjs-line.html" className="menu-link">
                  <span className="menu-text">Line</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="charts-chartjs-other.html" className="menu-link">
                  <span className="menu-text">Other</span>
                </a>
              </li>
            </ul>
          </li>

          <li className="menu-item">
            <a
              href="javascript:void(0)"
              data-fc-type="collapse"
              className="menu-link"
            >
              <span className="menu-icon">
                <i className="mdi mdi-map"></i>
              </span>
              <span className="menu-text"> Maps </span>
              <span className="menu-arrow"></span>
            </a>

            <ul className="sub-menu hidden">
              <li className="menu-item">
                <a href="maps-google.html" className="menu-link">
                  <span className="menu-text">Google Maps</span>
                </a>
              </li>
              <li className="menu-item">
                <a href="maps-vector.html" className="menu-link">
                  <span className="menu-text">Vector Maps</span>
                </a>
              </li>
            </ul>
          </li> */}
        </ul>
      </div>
    </div>
    ) : (<></>)
  );
}
