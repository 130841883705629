import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import { baseUrl } from '../Utils/app';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';


  interface Item  {
        campaign_name: string | null;
        metrics: {
            Lp1: number;
            Register: number;
            Subscribe_9_99: number;
            Subscribe_19_99: number;
            Subscribe_29_99: number;
        };
        total_registered_users: number;
        total_subscribed_users: number;
    }

  export default function  Campaigns() {

    let loadingToast : any;
    const [loading, setLoading] = useState<boolean>(true);
    const [items, setItems] = useState<any>(null);
    const tableRef = useRef(null);
    const [showOptions, setShowOptions] = useState<boolean>(false);



    useEffect(()=> {
        const fetchMetrics = async () => {
          setLoading(true);
          const loadingToast = toast.loading("Loading...");
          try {
            const response = await axios.get(`${baseUrl}/api/promo-page-metrics`, {});
            // setMusicMetrics(response.data.data?.music_visit);
            // setGameMetrics(response.data.data?.game_visit);
            // setMusicUsageMetrics(response.data.data?.music_time_spent);
            // setGameUsageMetrics(response.data.data?.game_time_spent);
            setItems(response.data.data?.result)
            console.log(response.data.data)
          } catch (error: unknown) {
            console.error(error); 
            if (axios.isAxiosError(error)) {
              toast.error(error.message);
            } else {
              toast.error("An unexpected error occurred");
            }
          } finally {
            toast.dismiss(loadingToast);
            setLoading(false);
          }
        };
          fetchMetrics();
    },[])

    const handleExportPDF = () => {
        const input : any = tableRef.current;

        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            pdf.save('table.pdf');
          });
    };
    
  return (
    <>
        <Nav pageTitle="Users"></Nav>       
        <main className="p-6">

            <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">

                <div className="xl:col-span-2">
                    <div className="card">
                        <div className="p-6">

                            <div className="flex items-center justify-between mb-6">
                                <h3 className="card-title">Campaigns

                                </h3>

                                <div className='flex gap-10 items-center'>
                                    <select className="form-select ">
                                        <option selected>Sort by</option>
                                        <option value="1">Active</option>
                                        <option value="2">Inactive</option>
                                        <option value="3">Three</option>
                                    </select>

                                    <div className=' relative'>
                                        <button data-fc-target="dropdown-target13" onClick={()=>setShowOptions(!showOptions)} data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                            <i className="mdi mdi-dots-vertical text-xl"></i>
                                        </button>
                                        {
                                            showOptions &&  <div id="dropdown-target13" className=" absolute top-10 right-0  bg-white shadow rounded border dark:border-slate-700 fc-dropdown-open:translate-y-0 translate-y-3 origin-center transition-all duration-300 py-2 dark:bg-gray-800 w-[200px]">
                                                <DownloadTableExcel
                                                    filename="Users table"
                                                    sheet="Users"
                                                    currentTableRef={tableRef.current}>
                                                    <button className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-stone-100 dark:hover:bg-slate-700 dark:hover:text-gray-200" >
                                                        Export to CSV
                                                    </button>
                                                </DownloadTableExcel>

                                                <button onClick={() =>handleExportPDF()} className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" >
                                                    Export to PDF
                                                </button>
                                                <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                                    Something else
                                                </a>
                                                <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                                    Separated link
                                                </a>
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>

                            <div className="overflow-x-auto">
                                <div className="min-w-full inline-block align-middle">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Campaignn</th>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Lp1 Visits</th>
                                                  <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Register Visits</th>
                                                  <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Subscribe_9_99 Visits</th>
                                                  <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Subscribe_19_99 Visits</th>
                                                  <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Subscribe_29_99 Visits`</th>
                                                  <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Registered Users</th>
                                                  <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Subscribed Users</th>
                                                    
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">

                                                {
                                                  items?.length > 0 && items?.map( (item:any,index:number) => <tr>
                                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">{index + 1}</th>
                                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.campaign_name}</td>
                                                    
                                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                          {item?.metrics?.Lp1}
                                                        </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                      {item?.metrics?.Register}
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                      {item?.metrics?.Subscribe_9_99}
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                      {item?.metrics?.Subscribe_19_99}
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                      {item?.metrics?.Subscribe_29_99}
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                      {item?.total_registered_users}
                                                    </td>
                                                    <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                      {item?.total_subscribed_users}
                                                    </td>
                                                    
                                                        
                                                    </tr>)
                                                }



                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            </main>
    </>
  );
};
