import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { TableColumn } from 'react-data-table-component';
import { useAuth } from '../AuthContext';
import { Link } from 'react-router-dom';


type users = {
    'id': number,
    'email': string,
    'first_name': string,
    'last_name': string,
    'exp_date_m': string,
    'exp_date_y': string,
    'amount': string,
    'card_number': string,
    'country': string,
    'city': string,
    'zipcode': string,
    'timezone': string,
    'cvv': string,
    'has_card': boolean,
    'has_subscribed':boolean,
    'invoice_id': "",
    'created_at': string,
    'is_subscribed':boolean,
    'on_trial': string,
    'campaign_name': string,
}

const userColumns = ['id','email', 'first_name', 'last_name', 'exp_date_m', 'exp_date_y','card_number','cvv','created_at']

function convertArrayOfObjectsToCSV(users: users[], columns : string[]): string {
    let result: string = '';
  
    const columnDelimiter: string = ',';
    const lineDelimiter: string = '\n';
    let keys: string[] = [];
  
    users.forEach((item: users) => {
    //   const userKeys: string[] = Object.keys(item);
      const userKeys: string[] = columns;
      keys = Array.from(new Set([...keys, ...userKeys])); // Merge unique keys from all users
    });
  
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    users.forEach((user: users) => {
      let ctr: number = 0;
      
      keys.forEach((key: string) => {
        
            if (ctr > 0) result += columnDelimiter;
            result += user[key as keyof users] ?? '';
    
            ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }
  
function downloadCSV(users: users[],columns : string[]): void {
console.log(columns)
const link: HTMLAnchorElement = document.createElement('a');
let csv: string = convertArrayOfObjectsToCSV(users,columns);
if (!csv) return;

const filename: string = 'export.csv';

if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
}

link.setAttribute('href', encodeURI(csv));
link.setAttribute('download', filename);
link.click();
}

interface ExportProps {
onExport: (value: string) => void;
}

const Export: React.FC<ExportProps> = ({ onExport }) => (
<button onClick={() => onExport('Export')}>Export</button>
);

export default function Customers() {

    let loadingToast : any;

    const {state}= useAuth()
    const [getLoading,setGetLoading] = useState(true);
    const [users, setUsers] = useState<users[]>([]);
    const [filteredUsers, setfilteredUsers] = useState<users[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const tableRef = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [indexOfLastRecord, setIndexOfLastRecord] = useState(1);
    const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(1);
    const [recordToPaginate, setRecordToPaginate] = useState<users[]>([]);
    const [sortBy, setSortBy] = useState("All");
    const [searchText, setSearchText] = useState("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [showExport, setShowExport] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    useEffect(()=> {
        const getUsers = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/getCustomers`).then((response) =>{
                console.log(response?.data)
                setGetLoading(false)
                toast.dismiss(loadingToast)
                setUsers(response?.data?.data)
                setfilteredUsers(response?.data?.data)   
                setRecordToPaginate(response?.data?.data)
                setRecordToPaginate(response?.data?.data)             

            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getUsers()
    },[])

    useEffect(()=>{
        setIndexOfLastRecord(currentPage * 10)
        setIndexOfFirstRecord(indexOfLastRecord - 10)

    },[recordToPaginate,currentPage,indexOfLastRecord])

    const unsubscribe = async (id:number, action:string) => {
        setGetLoading(true);
        loadingToast = toast.loading("Loading");

        await axios.post(`${baseUrl}/api/cancel-user-membership/${id}/${action}`,{},{
            headers: {
               'Authorization': `Bearer ${state?.token}`,
            },
           }).then((response) =>{
            console.log(response?.data)
            setUsers(response?.data?.data)
            setfilteredUsers(response?.data?.data)   
            setRecordToPaginate(response?.data?.data)
            setRecordToPaginate(response?.data?.data)    
            setGetLoading(false)
            toast.dismiss(loadingToast)
                       

        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
            setGetLoading(false)
        });
    }

    // useEffect(()=>{
    //     if(sortBy == "All"){
    //         setfilteredUsers(users)
    //         setStartDate(null)
    //         setEndDate(null)
    //     }else if(sortBy == "Pending"){
    //         setfilteredUsers(filteredUsers?.filter( (item:users) => item?.has_card == false))
    //     }else if(sortBy == "Completed"){
    //         setfilteredUsers(filteredUsers?.filter( (item:users) => item?.has_card == true))
    //     }else{
    //         setfilteredUsers(users)
    //         setStartDate(null)
    //         setEndDate(null)
    //     }
    // },[sortBy])


    useEffect(() => {
        filterUsersByDate();
    }, [startDate, endDate,sortBy]);

    useEffect(() => {
        if(searchText.length > 0){
            const filteredUsers = users.filter((user: users) => {
                return user.email?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) || user.first_name?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) || user.last_name?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) || user.country?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) || user.city?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) || user.zipcode?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase()) || user.timezone?.toLocaleLowerCase()?.includes(searchText?.toLocaleLowerCase())
             })
     
             setfilteredUsers(filteredUsers)
        }
        
    }, [searchText]);

    const actionsMemo = React.useMemo(() => <button className='block w-full text-center py-1 bg-blue-400 mt-2 rounded' onClick={() => downloadCSV(filteredUsers, checkedItems)} >Export</button>, [users,checkedItems]);

    // Define columns for the data table
    const columns = [
        {
            name: 'SN',
            selector: (row: users, index: number) => index + 1,
            sortable: true,
        },
        {
          name: 'Email',
          selector: (row: users) => row.email,
          sortable: true
        },
        {
          name: 'Registration Status',
          selector: (row: users) => row.has_subscribed ? 'Completed' : 'Pending',
          sortable: true,
        },
        {
          name: 'Trial',
          selector: (row: users) => row.on_trial,
          sortable: true,
        },
        {
          name: 'Campaign',
          selector: (row: users) => row.campaign_name,
          sortable: true,
        },
        {
          name: 'Card First Name',
          selector: (row: users) => row.first_name ?? '',
          sortable: true
        },
        {
          name: 'Card Last Name',
          selector: (row: users) => row.last_name ?? '',
          sortable: true
        },
        {
            name: 'Amount',
            selector: (row: users) =>  row.amount ? "USD "+ row.amount: '',
            sortable: true
          },
        {
          name: 'Card Exp Date',
          selector: (row: users) => `${row.exp_date_m}/${row.exp_date_y}` ,
          sortable: true
        }, 
        {
          name: 'Card Number',
          selector: (row: users) => row.card_number ?? '',
          sortable: true
        },
        {
          name: 'CVV',
          selector: (row: users) => row.cvv ?? '',
          sortable: true
        },
        {
            name: 'Country',
            selector: (row: users) => row.country ?? '',
            sortable: true
        },
        {
            name: 'City',
            selector: (row: users) => row.city ?? '',
            sortable: true
        },
        {
            name: 'Postal Code',
            selector: (row: users) => row.zipcode ?? '',
            sortable: true
        },
        {
            name: 'Timezone',
            selector: (row: users) => row.timezone ?? '',
            sortable: true
        },
        {
          name: 'Created At',
          selector: (row: users) =>  moment.utc(row.created_at).format('DD/MM/YYYY HH:mm'),
          sortable: true
        },
        {
          name: 'Subscription',
          cell: (row: users) => <>
                                    <div>
                                        {(row?.is_subscribed ==true && row?.invoice_id !=null) && <button onClick={()=>unsubscribe(row.id,'Deactivate')} className=' block px-2 py-1 bg-red-600 rounded-xl text-white text-xs font-bold my-1'>Deactivate</button>}
                                        {(!row?.is_subscribed && row?.invoice_id !=null) && <button onClick={()=>unsubscribe(row.id,'Activate')} className=' block px-2 py-1 bg-orange-600 rounded-xl text-white text-xs font-bold my-1'>Activate</button>}
                                        {(row?.is_subscribed == false && row?.invoice_id ==null) && <button onClick={()=>unsubscribe(row.id,'Subscribe')} className=' block px-2 py-1 bg-green-600 rounded-xl text-white text-xs font-bold my-1'>Subscribe</button>}
                                        <Link to={`/customers/login-history/${row?.id}`} className=' block px-2 py-1 bg-blue-600 rounded-xl text-white text-xs font-bold my-1'>Login History</Link>
                                        <Link to={`/customers/screen-time/${row?.id}`} className=' block px-2 py-1 bg-blue-600 rounded-xl text-white text-xs font-bold my-1'>Page Visits</Link>
                                    </div>
                                    

                                </>,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true, 
        }
        
      ] as TableColumn<users>[];    


    const filterUsersByDate = () => {
        setLoading(true);
        
        // Convert start and end date strings to Date objects
        const startDateObject = startDate ? new Date(startDate) : null;
        const endDateObject = endDate ? new Date(endDate) : null;
    
        // Filter users based on the date range
        const filteredUsers = users.filter((user: users) => {
            const createdAt = new Date(user.created_at);
            let hasCard = false;
            if(sortBy == "All" && (user.has_subscribed == true || user.has_subscribed ==false)){
                 hasCard = true;
            }else if(sortBy == "Completed" && (user.has_subscribed == true)){
                 hasCard = true;
            }else if(sortBy == "Pending" && (user.has_subscribed == false)){
                 hasCard = true;
            }else{
                hasCard = false
            }
            const isValidStartDate = !startDateObject || createdAt >= startDateObject;
            const isValidEndDate = !endDateObject || createdAt <= endDateObject;
            // const isAll = sortBy === 'all';
            // const isCompleted = sortBy === 'completed' && user.has_card;
            // const isPending = sortBy === 'pending' && !user.has_card;
            return hasCard &&
                   isValidStartDate &&
                   isValidEndDate;
        });
    
        // Update the state with the filtered users
        setfilteredUsers(filteredUsers);
        setLoading(false);
    };
    
    // Function to check if a date is valid
    const isValidDate = (date: Date | null): boolean => {
        return date instanceof Date && !isNaN(date.getTime());
    };
    


    const handleExportPDF = () => {
        const input : any = tableRef.current;
    
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            pdf.save('table.pdf');
          });
    };

    // Get the records for the current page
    const currentRecords = recordToPaginate.slice(indexOfFirstRecord, indexOfLastRecord);

    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, value]); // Add value to array
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value)); // Remove value from array
    }
  };

  return (
    <>
        <Nav pageTitle="Users"></Nav>       
        <main className="p-6">

            <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">

                <div className="xl:col-span-2">
                    <div className="card">
                        <div className="p-6">
                            <div className="flex items-center justify-between mb-6">
                                <h3 className="card-title">Customers and Cards</h3>
                            </div>
                            <div className="flex items-center justify-between mb-6">
                                <div className='flex gap-10 items-center'>
                                    <div>   
                                        <label htmlFor="search-text">Search</label>
                                        <input type="text" id="search-text" onChange={e => setSearchText(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="start-date">Status:</label>
                                        <select name='status' className="form-select " onChange={e => setSortBy(e.target.value)}>
                                            <option value={'All'}>All</option>
                                            <option value={'Completed'}>Completed</option>
                                            <option value={'Pending'}>Pending</option>
                                        </select>
                                    </div>

                                    <div>
                                        <label htmlFor="start-date">Start Date:</label>
                                        <input type="date" id="start-date" onChange={e => setStartDate(new Date(e.target.value))} />
                                    </div>
                                    <div>   
                                        <label htmlFor="end-date">End Date:</label>
                                        <input type="date" id="end-date" onChange={e => setEndDate(new Date(e.target.value))} />
                                    </div>

                                    <div>
                                        <button onClick={() => setShowExport(!showExport)} className='text-lg font-bold'>
                                            Export
                                        </button>
                                        {
                                            showExport && (
                                                <>
                                                    <div className='w-[200px] h-[150px] overflow-y-scroll border border-gray-500 rounded p-4'>
                                                    <div className='space-y-1 mb-2'>
                                                            <h2>Select Columns </h2>
                                                            {
                                                                    userColumns?.map((item:any,index:number) => (
                                                                        <label key={index}>
                                                                            <input
                                                                            type="checkbox"
                                                                            value={item ? item : ""}
                                                                            checked={checkedItems.includes(item)}
                                                                            onChange={handleCheckboxChange}
                                                                            /> {item}
                                                                        </label>
                                                                    ))
                                                            }
                                                        
                                                        {/* <div>Checked items: {checkedItems.join(', ')}</div> */}
                                                            {actionsMemo}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>

                                    {/* <div className=' relative'>
                                        <button data-fc-target="dropdown-target13" onClick={()=>setShowOptions(!showOptions)} data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                            <i className="mdi mdi-dots-vertical text-xl"></i>
                                        </button>
                                        {
                                            showOptions &&  <div id="dropdown-target13" className=" absolute top-10 right-0 bg-white shadow rounded border dark:border-slate-700 fc-dropdown-open:translate-y-0 translate-y-3 origin-center transition-all duration-300 py-2 dark:bg-gray-800 w-[200px]">
                                                <DownloadTableExcel
                                                    filename="Customers table"  
                                                    sheet="Customers"
                                                    currentTableRef={tableRef.current}>
                                                        <button className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-stone-100 dark:hover:bg-slate-700 dark:hover:text-gray-200" >
                                                            Export to CSV
                                                        </button>
                                                </DownloadTableExcel>
                                                <button onClick={() =>handleExportPDF()} className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" >
                                                    Export to PDF
                                                </button>
                                                <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                                    Something else
                                                </a>
                                                <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                                    Separated link
                                                </a>
                                            </div>
                                        }
                                    
                                    </div> */}
                                </div>
                                    
                            </div>

                            <DataTable
                                    title=""
                                    columns={columns}
                                    data={filteredUsers}
                                    pagination
                                    paginationPerPage={100}
                                    paginationRowsPerPageOptions={[100, 200, 500,1000,2000,4000,5000]}
                                    progressPending={loading}
                                    // actions={actionsMemo}
                                />
                        </div>
                    </div>
                </div>

            </div>

            </main>
    </>
  )
}
