import React from 'react'

export default function Footer() {
  return (
    <footer className="footer card rounded-none mt-auto">
        <div className="h-16 flex items-center px-8 rounded-none">
            <div className="flex md:justify-between justify-center w-full gap-4">
                <div>
                    <script>document.write(new Date().getFullYear())</script> © OneHubplay 
                </div>
                <div className="md:flex hidden gap-4 item-center md:justify-end">
                    <a href="#" className="text-sm leading-5 text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white">About</a>
                    <a href="#" className="text-sm leading-5 text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white">Help</a>
                    <a href="#" className="text-sm leading-5 text-zinc-600 transition hover:text-zinc-900 dark:text-zinc-400 dark:hover:text-white">Contact Us</a>
                </div>
            </div>
        </div>
    </footer>
  )
}
