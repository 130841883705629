import React,{useMemo, useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav'
import countryList from 'react-select-country-list'
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';

export default function MediaUpload() {
    const options = useMemo(() => countryList().getData(), [])
    const coverImageRef = useRef<any>();
    const bannerImageRef = useRef<any>();
    const fileRef = useRef<any>();
    const navigate = useNavigate();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [formControls,setFormControls] = useState({
        'type':'movie',
        'description':'',
        'rating':'',
        'duration':'',
        'genre':'',
        'author':'',
        'release_date':'',
        'production_country':'',
        'title':'',
        'featured':false,
        'cover_image_small':'',
        'file_link':'',

    });
    const [loading,setLoading] = useState(false);
    let loadingToast:any;

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        loadingToast = toast.loading("Loading");

        let mediaFileUrl:any='';
        let coverFileUrl:any='';
        let bannerFileUrl:any='';

        Promise.all([
            fileUploadPromise((coverImageRef.current && coverImageRef.current.files) ? coverImageRef.current.files[0] : null),
            fileUploadPromise((fileRef.current && fileRef.current.files) ? fileRef.current.files[0] : null),
            fileUploadPromise((bannerImageRef.current && bannerImageRef.current.files) ? bannerImageRef.current.files[0] : null)])
            .then(async (results) => {
                console.log(results)
                coverFileUrl = results[0]
                mediaFileUrl = results[1]
                bannerFileUrl = results[2]

                let postData = {
                    ...formControls,
                    'cover_image_small':coverFileUrl,
                    'file_link':mediaFileUrl,
                    'cover_image_large':bannerFileUrl--
                }
                axios.defaults.withCredentials = true;
                await axios.post(`${baseUrl}/api/contentItem`,postData).then((response) =>{
                    console.log(response)
                    setLoading(false)
                    toast.dismiss(loadingToast)
                    toast.success("Media created Successfully");
                    setFormControls({
                        'type':'movie',
                        'description':'',
                        'rating':'',
                        'duration':'',
                        'genre':'',
                        'author':'',
                        'release_date':'',
                        'production_country':'',
                        'title':'',
                        'featured':false,
                        'cover_image_small':'',
                        'file_link':'',
                    })
                }).catch((error)=>{
                    console.log(error)
                    toast.dismiss(loadingToast)
                    toast.error(error?.message);
                    setLoading(false)
                });

            }).catch((e)=>{
                console.log(e)
                toast.error(e?.message);
            })
    }

    const fileUploadPromise = (imageFile:File|undefined|null) => {
        return new Promise (async (resolve, reject) => {
            if(imageFile){
                    let uuid = require("uuid");
                    try{
                        let fileName = imageFile.name;
                        let fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

                        let uploadTask = storage.ref(`/fileUploads/${uuid.v4()}.${fileExtension}`).put(imageFile);
                        uploadTask.then(uploadSnapshot => {
                            return uploadSnapshot.ref.getDownloadURL();
                        }).then(url => {
                            resolve(url);
                        }).catch(err => {
                            reject(err);
                        })
                    }catch(e){
                        console.log(e)
                        reject(e);

                    }
            }else{
                resolve("");
            }
        })
    }

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

  return (
    <>
        <Nav pageTitle="Media Upload"></Nav>
        <main className="p-6">
            <div className="flex flex-col gap-6">

                {/* Upload Media */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Upload Media</h4>

                        <form onSubmit={(e) => handleSubmit(e)} className=" grid lg:grid-cols-2 gap-6">
                            {/* Section 1 */}
                            <div className="">

                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Media Title
                                    </label>
                                    <input
                                        type="text"
                                        id="example-password"
                                        className="form-input"
                                        value={formControls.title} required onChange={(e) => setFormControls({...formControls, 'title':e.target.value})}
                                    />
                                </div>

                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Media Type
                                    </label>
                                    <select id="example-multiselect" required value={formControls.type} onChange={(e) => setFormControls({...formControls, 'type':e.target.value})}  className="form-input">
                                        <option value="movie">Movie</option>
                                        <option value="music">Music</option>
                                        <option value="ebook">E-book</option>
                                        <option value="audioBook">Audio Book</option>
                                    </select>
                                </div>

                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-palaceholder"
                                    >
                                        Media Description
                                    </label>
                                    <textarea
                                        className="form-input"
                                        id="example-textarea"
                                        rows={2}
                                        required 
                                        value={formControls.description}
                                        onChange={(e) => setFormControls({...formControls, 'description':e.target.value})}
                                    ></textarea>
                                </div>

                                {/* <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Media Rating
                                    </label>
                                    <input
                                        type="number"
                                        id="example-password"
                                        className="form-input"
                                        value={formControls.rating} required={formControls.type == "movie"} max={10} onChange={(e) => setFormControls({...formControls, 'rating':e.target.value})}
                                    />
                                </div>

                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Media Duration
                                    </label>
                                    <input
                                        type="number"
                                        id="example-password"
                                        className="form-input"
                                        value={formControls.duration} required={formControls.type == "movie" || formControls.type == 'music'}  onChange={(e) => setFormControls({...formControls, 'duration':e.target.value})} 
                                    />
                                </div> */}

                                {
                                    formControls.type == 'movie' && <div className='mb-[10px]'>
                                        <label className='block text-gray-600 mb-2'> Rating </label>
                                        <input type='number' value={formControls.rating} required={formControls.type == "movie"} max={10} onChange={(e) => setFormControls({...formControls, 'rating':e.target.value})} className='form-input' />
                                    </div>
                                }
                                {
                                    (formControls.type == 'movie' || formControls.type == 'music')&& <div className='mb-[10px]'>
                                        <label className='block text-gray-600 mb-2'> Duration </label>
                                        <input value={formControls.duration} required={formControls.type == "movie" || formControls.type == 'music'} type='number'  onChange={(e) => setFormControls({...formControls, 'duration':e.target.value})} className='form-input' />
                                    </div>
                                }

                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Media Genre(s)
                                    </label>
                                    <input
                                        type="text"
                                        id="example-password"
                                        className="form-input"
                                        required value={formControls.genre} onChange={(e) => setFormControls({...formControls, 'genre':e.target.value})} 
                                    />
                                </div>

                            </div>

                            {/* Section 2 */}
                            <div>
                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Media Author
                                    </label>
                                    <input
                                        type="text"
                                        id="example-password"
                                        className="form-input"
                                        required
                                        value={formControls.author} onChange={(e) => setFormControls({...formControls, 'author':e.target.value})}
                                    />
                                </div>
                                {
                                    (formControls.type == 'movie' || formControls.type == 'music') &&  <div className='mb-[10px]'>
                                            <label className="block text-gray-600 mb-2"> Release date </label>
                                            <input type="number" id="yearInput" placeholder='2000' name="year" min="1900" max="2099" step="1" value={formControls.release_date} required={formControls.type == "movie" || formControls.type == 'music'} onChange={(e) => setFormControls({...formControls, 'release_date':e.target.value})} className='form-input' />
                                        </div>
                                }   

                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="example-password"
                                    >
                                        Production Country
                                    </label>
                                    <select id="example-multiselect" value={formControls.production_country} onChange={(e) => setFormControls({...formControls, 'production_country':e.target.value})} className="form-input">
                                        <option>Select Country</option>
                                        {
                                            options.map((op) => <option key={op.value} value={op.value}>{op.label}</option>)
                                        }
                                    </select>
                                </div>
                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="simpleinput"
                                    >
                                        Cover Image
                                    </label>
                                    <input
                                        type="file"
                                        id="example-fileinput"
                                        className="form-input border"
                                        required ref={coverImageRef}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="simpleinput"
                                    >
                                        Cover Banner
                                    </label>
                                    <input
                                        type="file"
                                        id="example-fileinput"
                                        className="form-input border"
                                        ref={bannerImageRef}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label
                                        className="block text-gray-600 mb-2"
                                        htmlFor="simpleinput"
                                    >
                                        Media File
                                    </label>
                                    <input
                                        type="file"
                                        id="example-fileinput"
                                        className="form-input border"
                                        ref={fileRef} required
                                    />
                                </div>

                                <div className="flex flex-col gap-2">
                                    <div className="flex items-center">
                                        <input type="checkbox" value={0} onChange={(e) => setFormControls({...formControls, 'featured': !formControls.featured })} className="form-checkbox rounded text-primary" id="formSwitch1" />
                                        <label className="ms-1.5" htmlFor="formSwitch1">Set as Featured Item</label>
                                    </div>

                                </div>
                            </div>   

                            <div className='col-span-2 '>
                                {
                                    !loading?(
                                        <button type="submit" className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Upload Media</button>
                                    ):(
                                        <button type="button" disabled className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Loading</button>
                                    )
                                } 
                            </div>  
                        </form>
                    </div>
                </div>

            </div>
        </main>
    </>
  )
}
