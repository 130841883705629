import React,{useState} from 'react'
import { useAuth } from '../AuthContext';
import { useLocation,useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios'
import { baseUrl } from '../Utils/app';
import { Link } from 'react-router-dom';

export default function Login() {
    const { state, dispatch } = useAuth();
    const location = useLocation()
    
    const [formControls,setFormControls] = useState({
        'email':'',
        'password':'',
    });

    const [loading,setLoading] = useState(false);
    let loadingToast: any;

    const navigate = useNavigate();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        let postData = {
            ...formControls,
        }
        setLoading(true)

        await axios.post(`${baseUrl}/api/admin-login`,postData).then((response) =>{
            console.log(response)
            setLoading(false)
            toast.dismiss(loadingToast)
            setFormControls({
                'email':'',
                'password':'',
            })
            if(response?.data?.data?.token){
                 dispatch({ type: 'LOGIN-USER', payload: response?.data?.data });
                 navigate('/')
            }
            
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.message);
            setLoading(false)
        });
    }
  return (
    <div className="relative bg-cover bg-center " style={{backgroundImage: "url(assets/images/bg-auth3.png)"}} >
        <div className="absolute inset-0 dark:bg-black/80"></div>
        <div className="relative flex flex-col items-center justify-start py-20 h-screen">

            
            <div className="pb-10">

                <a href="index.html" className="flex justify-center">
                    <img src="/assets/images/logo.png" alt="logo" className="h-5 block dark:hidden" />
                    <img src="/assets/images/logo.png" alt="logo" className="h-5 hidden dark:block" />
                </a>
                {/* <p className="text-gray-400 mt-3 dark:text-gray-300">Responsive Admin Dashboard</p> */}
            </div>

            <form onSubmit={(e)=> handleSubmit(e)} className="flex justify-center">
                <div className="max-w-lg px-4 mx-auto">

                    <div className="card overflow-hidden">
                        <div className="p-9">

                            <div className="text-center mx-auto w-full">
                                <h4 className="text-center text-lg uppercase font-bold mb-8">Sign In</h4>
                            </div>

                            <div className="sm:w-80">
                                <div className="mb-6 space-y-2">
                                    <label htmlFor="emailaddress" className="font-medium text-gray-500">Email address</label>
                                    <input className="form-input placeholder:text-gray-400 text-gray-400" type="email" id="emailaddress" required value={formControls.email} onChange={(e) => setFormControls({...formControls, 'email':e.target.value})} placeholder="Enter your email" />
                                </div>

                                <div className="mb-6 space-y-2">
                                    <label htmlFor="password" className="font-medium text-gray-500">Password</label>
                                    <input required value={formControls.password} onChange={(e) => setFormControls({...formControls, 'password':e.target.value})} type="password" id="password" className="form-input placeholder:text-gray-400 text-gray-400" placeholder="Enter your password"/>
                                </div>


                                <div className="text-center mb-6">
                                    <button className="btn bg-primary w-full bg-green-500 text-white" type="submit"> Log In </button>
                                </div>
                            </div> 

                        </div>
                    </div> 

                    <div className="text-center mt-8">
                        {/* <p className="text-gray-400 dark:text-gray-300">Don't have an account? <Link to={'/register'}  className="ms-1 text-gray-800 dark:text-gray-100"><b>Sign Up</b></Link></p> */}
                    </div>

                </div>
            </form>

        </div>
    </div>
  )
}
