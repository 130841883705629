import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';
import { Link } from 'react-router-dom';


type MediaItem = {
    title: string,
    description: string,
    type: string,
    rating?: string,
    duration?: string,
    genre?: string,
    author?: string,
    production_country?: string,
    featured: string,
    release_date: string,
    cover_image_small: string,
    cover_image_large?: string,
    file_link: string,
}

export default function Movies() {
    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(1);
    const [loading,setLoading] = useState(false);
    const [formControl, setFormControl] = useState({
        email: '',
        password: ''
    })
    const [items, setItems] = useState({
        'movies':[],
        'musics': [],
        'ebooks': [],
        'audioBooks': []
    });

    let loadingToast:any;

    useEffect(()=>{
        setLoading(true);
        loadingToast = toast.loading("Loading",);

        const getItems = async () => {
           return await axios.get(`${baseUrl}/api/contentItem`)
        }

        getItems().then((resp) => {
            setItems(resp.data.data)
            toast.dismiss(loadingToast)
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
        })
    },[])

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);


  return (
    <>
        <Nav pageTitle="Users"></Nav>       
        <main className="p-6">

            <div className='w-[100%] bg-white rounded-lg p-[50px]' >
                <h1 className='mb-[20px] text-gray-500 text-3xl text-center font-semibold'> Media Item List </h1>
                <Link to={'/media-upload'} className=" block bg-[#18AD58] px-[40px] py-[5px] text-[20px] rounded-full mx-auto text-white font-semibold uppercase text-center mb-4 w-max">Upload New Item</Link>
                <div>
                    <ul className='flex justify-evenly text-lg'>
                        <li><button onClick={()=>setActiveTab(1)} className={activeTab == 1? 'text-[#18AD58] font-bold':'text-gray-500'}>Movies</button> </li>
                        <li><button onClick={()=>setActiveTab(2)} className={activeTab == 2? 'text-[#18AD58] font-bold':'text-gray-500'}>Music</button></li>
                        <li><button onClick={()=>setActiveTab(3)} className={activeTab == 3? 'text-[#18AD58] font-bold':'text-gray-500'}>E-books</button></li>
                        <li><button onClick={()=>setActiveTab(4)} className={activeTab == 4? 'text-[#18AD58] font-bold':'text-gray-500'}>Audio Books</button></li>
                    </ul>
                </div>
                <div className='grid grid-cols-2'>
                    {
                        activeTab == 1 && <>
                            {
                                items?.movies?.length > 0 && items?.movies?.map((item:MediaItem) => <div className=" flex pt-[50px] gap-x-[20px]">
                                    <div className="w-[200px] ">
                                        <img className="w-full object-cover" src={item?.file_link} />
                                    </div>
                                    <div className="flex-[2]">
                                        <h1 className="text-[#18AD58] text-[16px] font-bold">{item?.title}</h1>
                                        <h3 className="mb-3 font-semibold text-xs text-gray-500">{item.rating}/10 • {item.duration} • {item.release_date}</h3>
                                        <p className="text-gray-500 text-xs mb-3">
                                            {item.description}
                                        </p>
                                        <p className="text-gray-500 text-xs">
                                        <span className="font-bold">GENRES:</span> {item.genre}
                                        </p>
                                        <p className="text-gray-500 text-xs ">
                                            <span className="font-bold">PRODUCTION COUNTRY:</span> {item.production_country}
                                        </p>
                                        <p className="text-gray-500 text-xs ">
                                            <span className="font-bold">DIRECTOR:</span> {item.author}
                                        </p>
                                    </div>
                                </div> )
                            }
                        </>
                    }
                    {
                        activeTab == 2 && <>
                            {
                                items?.musics?.length > 0 && items?.musics?.map((item:MediaItem) => <div className=" flex pt-[50px] gap-x-[20px]">
                                    <div className="w-[200px] ">
                                        <img className="w-full object-cover" src={item?.file_link} />
                                    </div>
                                    <div className="flex-[2]">
                                        <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                        <h3 className="mb-3 font-semibold text-xs text-gray-500">{item.rating}/10 • {item.duration} • {item.release_date}</h3>
                                        <p className="text-gray-500 text-xs mb-3">
                                            {item.description}
                                        </p>
                                        <p className="text-gray-500 text-xs">
                                        <span className="font-bold">GENRES:</span> {item.genre}
                                        </p>
                                        <p className="text-gray-500 text-xs ">
                                            <span className="font-bold">Artist:</span> {item.author}
                                        </p>
                                    </div>
                                </div> )
                            }
                        </>
                    }
                    {
                        activeTab == 3 && <>
                            {
                                items?.ebooks?.length > 0 && items?.ebooks?.map((item:MediaItem) => <div className=" flex pt-[50px] gap-x-[20px]">
                                    <div className="w-[200px] ">
                                        <img className="w-full object-cover" src={item?.file_link} />
                                    </div>
                                    <div className="flex-[2]">
                                        <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                        <p className="text-gray-500 text-xs mb-3">
                                            {item.description}
                                        </p>
                                    </div>
                                </div> )
                            }
                        </>
                    }
                    {
                        activeTab == 4 && <>
                            {
                                items?.audioBooks?.length > 0 && items?.audioBooks?.map((item:MediaItem) => <div className=" flex pt-[50px] gap-x-[20px]">
                                    <div className="w-[200px] ">
                                        <img className="w-full object-cover" src={item?.file_link} />
                                    </div>
                                    <div className="flex-[2]">
                                        <h1 className="text-[#18AD58] text-[16px] font-bold">{item.title}</h1>
                                        <p className="text-gray-500 text-xs mb-3">
                                            {item.description}
                                        </p>
                                    </div>
                                </div> )
                            }
                        </>
                    }
                </div>
            </div>

            

        </main>
    </>
  )
}
