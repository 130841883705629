import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';


type users = {
    'id': number,
    'email': string,
    'first_name': string,
    'last_name': string,
    'exp_date_m': string,
    'exp_date_y': string,
    'card_number': string,
    'cvv': string,
}


export default function Payment() {
    let loadingToast : any;
    const [getLoading,setGetLoading] = useState(true);
    const [users, setUsers] = useState<users[]>([]);
    const [showOptions, setShowOptions] = useState<boolean>(false);

    useEffect(()=> {
        const getUsers = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/getUsers`).then((response) =>{
                console.log(response?.data)
                setGetLoading(false)
                toast.dismiss(loadingToast)
                setUsers(response?.data?.data)                

            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getUsers()
    },[])

  return (
    <>
    <Nav pageTitle="Users"></Nav>       
    <main className="p-6">

        <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">

            <div className="xl:col-span-2">
                <div className="card">
                    <div className="p-6">

                        <div className="flex items-center justify-between mb-6">
                            <h3 className="card-title">Payments and Subscription</h3>
                            <div className='flex gap-10 items-center'>
                                <select className="form-select ">
                                    <option selected>Sort by</option>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                    <option value="3">Three</option>
                                </select>

                                <div className=' relative'>
                                    <button data-fc-target="dropdown-target13" onClick={()=>setShowOptions(!showOptions)} data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                        <i className="mdi mdi-dots-vertical text-xl"></i>
                                    </button>
                                    {
                                        showOptions &&  <div id="dropdown-target13" className=" absolute top-10 right-0 bg-white shadow rounded border dark:border-slate-700 fc-dropdown-open:translate-y-0 translate-y-3 origin-center transition-all duration-300 py-2 dark:bg-gray-800 w-[200px]">
                                        <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-stone-100 dark:hover:bg-slate-700 dark:hover:text-gray-200" href="javascript:void(0)">
                                            Export to CSV
                                        </a>
                                        <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                            Export to PDF
                                        </a>
                                        <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                            Something else
                                        </a>
                                        <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                            Separated link
                                        </a>
                                    </div>
                                    }
                                
                                </div>
                            </div>
                                
                        </div>

                        <div className="overflow-x-auto">
                            <div className="min-w-full inline-block align-middle">
                                <div className="overflow-hidden">
                                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">email</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Card first Name</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Card Last Name</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Card Exp Date</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Card Number</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">CVV</th>
                                                <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                            

                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        </main>
</>
  )
}
