import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';

type Policy = {
    id?: number|null
    page_title: string,
    page_content: string,
    type:string,
    updated_at?:string
    created_at?:string
} & {
    [key: string]: any;
}

export default function PolicyForm() {
    const [terms, setTerms] = useState<Policy>({
        page_title:'',
        page_content: '',
        type: "t&c"
    })

    const [privacy, setPrivacy] = useState<Policy>({
        page_title:'',
        page_content: '',
        type: "privacy"
    })

    const [cookie, setCookie] = useState<Policy>({
        page_title:'',
        page_content: '',
        type: "cookie"
    })

    const [loading,setLoading] = useState(false);
    const [getLoading,setGetLoading] = useState(true);
    let loadingToast : any;


    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    useEffect(()=>{
        console.log(terms)
    },[terms])

    useEffect(()=>{
        const getPolicies = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/getPolicy`).then((response) =>{
                console.log(response?.data)
                setGetLoading(false)
                toast.dismiss(loadingToast)
                
                setTerms(response?.data?.data?.filter((item:Policy)=> item?.type == "t&c")[0])
                setPrivacy(response?.data?.data?.filter((item:Policy)=> item?.type == "privacy")[0])
                setCookie(response?.data?.data?.filter((item:Policy)=> item?.type == "cookie")[0])

            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getPolicies();
    },[]);

    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        setLoading(true);
        loadingToast = toast.loading("Loading");

        const postData = {policy:[terms,privacy,cookie ]}

        await axios.post(`${baseUrl}/api/savePolicy`,postData).then((response) =>{
            console.log(response)
            setLoading(false)
            toast.dismiss(loadingToast)
            toast.success("Media updated Successfully");
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
            setLoading(false)
        });
    }


  return (
    <>
        <Nav pageTitle="Edit Policy Screen"></Nav>
        <main className="p-6">
            <form onSubmit={(e)=>handleSubmit(e)} className="flex flex-col gap-6">

                {/* Terrms and Condition */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Terms and Condition</h4>

                        <div className=" gap-6">
                            <div>
                                <div className="grid lg:grid-cols-1 gap-6">

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Page - Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            required
                                            value={terms?.page_title}
                                            onChange={(e)=> setTerms({...terms,page_title:e.target.value})}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-palaceholder"
                                        >
                                            Page - Content
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            value={terms?.page_content}
                                            rows={2}
                                            required
                                            onChange={(e)=> setTerms({...terms,page_content:e.target.value})}
                                        ></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* PRIVACY POLICY */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">PRIVACY POLICY</h4>

                        <div className=" gap-6">
                            <div>
                                <div className="grid lg:grid-cols-1 gap-6">

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Page - Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            required
                                            value={privacy?.page_title}
                                            onChange={(e)=> setPrivacy({...privacy,page_title:e.target.value})}

                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-palaceholder"
                                        >
                                            Page - Content
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            rows={2}
                                            required
                                            value={privacy?.page_content}
                                            onChange={(e)=> setPrivacy({...privacy,page_content:e.target.value})}

                                        ></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* COOKIE POLICY */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">COOKIE POLICY</h4>

                        <div className=" gap-6">
                            <div>
                                <div className="grid lg:grid-cols-1 gap-6">

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Page - Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            value={cookie?.page_title}
                                            required
                                            onChange={(e)=> setCookie({...cookie,page_title:e.target.value})}

                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-palaceholder"
                                        >
                                            Page - Content
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            rows={2}
                                            value={cookie?.page_content}
                                            required
                                            onChange={(e)=> setCookie({...cookie,page_content:e.target.value})}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-5'>
                    {
                        !loading?(
                            <button type="submit" className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Submit</button>
                        ):(
                            <button type="button" disabled className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Loading</button>
                        )
                    }  
                </div>
            </form>

            
        </main>
    </>
  )
}
