import React ,{useState, useEffect} from 'react';
import './App.css';
import './tailwind.css'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from './Pages/Dashboard';
import Nav from './Layout/Nav';
import SideNav from './Layout/SideNav';
import Footer from './Layout/Footer';
import IndexForm from './Pages/IndexForm';
import AboutForm from './Pages/AboutForm';
import HelpForm from './Pages/HelpForm';
import PolicyForm from './Pages/PolicyForm';
import MediaUpload from './Pages/MediaUpload';
import { Toaster } from 'react-hot-toast';
import Users from './Pages/Users';
import Movies from './Pages/Movies';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Customers from './Pages/Customers';
import axios from 'axios'
import { useAuth } from './AuthContext';
import Payment from './Pages/Payment';
import CustomerPageVisits from './Pages/CustomerPageVisits';
import CustomerLoginHistory from './Pages/CustomerLoginHistory';
import Metrics from './Pages/Metrics';
import Campaigns from './Pages/Campaigns';
import PrimepayRecords from './Pages/PrimepayRecords';


function App() {
  axios.defaults.withCredentials = true;
  const { state, dispatch } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const getUserData = async ()=>{
      let userData =  localStorage.getItem('USER_DATA');
      let tokenData = localStorage.getItem('USER_TOKEN');
      if(userData != "null" && tokenData != "null" && userData != null && tokenData != null && userData != "" && tokenData != "/reset-password"){
        dispatch({ type: 'RESTORE', payload: {user: JSON.parse(userData), token: JSON.parse(tokenData)} })
        setLoading(false)
      }else{
        setLoading(false)
      }
    }
    getUserData();
  },[])

  return (
    <BrowserRouter>
      <div className="flex wrapper">
        <SideNav></SideNav>
        <div className='page-content'>
        {
          (!loading && state?.isAuth) ? (
            <Routes>
              <Route path="/" index element={<Dashboard />}></Route>
              <Route path="/edit-index"  element={<IndexForm />}></Route>
              <Route path="/edit-about"  element={<AboutForm />}></Route>
              <Route path="/edit-help"  element={<HelpForm />}></Route>
              <Route path="/edit-policy"  element={<PolicyForm />}></Route>
              <Route path="/media-upload"  element={<MediaUpload />}></Route>
              <Route path="/users"  element={<Users />}></Route>
              <Route path="/customers"  element={<Customers />}></Route>
              <Route path="/payments"  element={<Payment />}></Route>
              <Route path="/media"  element={<Movies />}></Route>
              <Route path="/register"  element={<Register />}></Route>
              <Route path="/customers/login-history/:id"  element={<CustomerLoginHistory />}></Route>
              <Route path="/customers/screen-time/:id"  element={<CustomerPageVisits />}></Route>
              <Route path="/metrics" index element={<Metrics />}></Route>
              <Route path="/campaigns" index element={<Campaigns />}></Route>
              <Route path="/prime-play" index element={<PrimepayRecords />}></Route>

            </Routes>
          ):(
            <Routes>
              <Route path="/" index element={<Login />}></Route>
            </Routes>
          )
        }
          <Footer></Footer>
          
        </div>
        
      </div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
            // Define default options
            className: '',
            duration: 5000,
            style: {
            background: '#363636',
            color: '#fff',
            },

            // Default options for specific types
            success: {
            duration: 3000,

            },
        }}
    />
    </BrowserRouter>
  );
}

export default App;
