import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';


export default function AboutForm() {

    const scn1_bg_image_ref = useRef<any>();
    const scn3_bg_image_ref = useRef<any>();
    const scn2_bg_image_ref = useRef<any>();
    const scn4_bg_image_ref = useRef<any>();
    const scn4_item1_image_ref = useRef<any>();
    const scn4_item2_image_ref = useRef<any>();
    const scn4_item3_image_ref = useRef<any>();
    const scn5_bg_image_ref = useRef<any>();
    const scn5_left_image_ref = useRef<any>();

    const [formControls,setFormControls] = useState({
        'scn1_bg_image':'',
        'scn1_title':'',
        'scn2_bg_image':'',
        'scn2_title':'',
        'scn2_description1':'',
        'scn2_description2':'',
        'scn3_bg_image':'',
        'scn3_title':'',
        'scn3_description':'',
        'scn4_bg_image':'',
        'scn4_item1_image':"",
        'scn4_item1_title':'',
        'scn4_item2_image':'',
        'scn4_item2_title':'',
        'scn4_item3_image':'',
        'scn4_item3_title':'',
        'scn5_bg_image':"",
        'scn5_left_image':'',
        'scn5_title':'',
        'scn5_description':'',
    });
    const [loading,setLoading] = useState(false);
    const [getLoading,setGetLoading] = useState(true);

    let loadingToast : any;

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    useEffect(()=> {
        const getAbout = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/getAbout`).then((response) =>{
                console.log(response?.data)
                setGetLoading(false)
                toast.dismiss(loadingToast)
                setFormControls(response?.data?.data)                

            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getAbout()
    },[])

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        loadingToast = toast.loading("Loading");

        let scn1_bg_image_url : any ='';
        let scn3_bg_image_url : any ='';
        let scn2_bg_image_url : any='';
        let scn4_bg_image_url : any='';
        let scn4_item1_image_url : any ='';
        let scn4_item2_image_url : any ='';
        let scn4_item3_image_url : any='';
        let scn5_bg_image_url : any ='';
        let scn5_left_image_url : any='';

        Promise.all([
            fileUploadPromise((scn1_bg_image_ref.current && scn1_bg_image_ref.current.files) ? scn1_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn3_bg_image_ref.current && scn3_bg_image_ref.current.files) ? scn3_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn2_bg_image_ref.current && scn2_bg_image_ref.current.files) ? scn2_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn4_bg_image_ref.current && scn4_bg_image_ref.current.files) ? scn4_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn4_item1_image_ref.current && scn4_item1_image_ref.current.files) ? scn4_item1_image_ref.current.files[0]: null),
            fileUploadPromise((scn4_item2_image_ref.current && scn4_item2_image_ref.current.files) ? scn4_item2_image_ref.current.files[0]: null),
            fileUploadPromise((scn4_item3_image_ref.current && scn4_item3_image_ref.current.files) ? scn4_item3_image_ref.current.files[0]: null),
            fileUploadPromise((scn5_bg_image_ref.current && scn5_bg_image_ref.current.files) ? scn5_bg_image_ref.current.files[0]: null),
            fileUploadPromise((scn5_left_image_ref.current && scn5_left_image_ref.current.files) ? scn5_left_image_ref.current.files[0]: null)
        ])
            .then(async (results) => {
                console.log(results)
                scn1_bg_image_url = results[0]
                scn3_bg_image_url = results[1]
                scn2_bg_image_url = results[2]
                scn4_bg_image_url = results[3]
                scn4_item1_image_url = results[4]
                scn4_item2_image_url = results[5]
                scn4_item3_image_url = results[6]
                scn5_bg_image_url = results[7]
                scn5_left_image_url = results[8]

                results[0] != ""? scn1_bg_image_url = results[0] : scn1_bg_image_url = formControls?.scn1_bg_image
                results[1] != ""? scn3_bg_image_url = results[1] : scn3_bg_image_url = formControls?.scn3_bg_image
                results[2] != ""? scn2_bg_image_url = results[2] : scn2_bg_image_url = formControls?.scn2_bg_image
                results[3] != ""? scn4_bg_image_url = results[3] : scn4_bg_image_url = formControls?.scn4_bg_image
                results[4] != ""? scn4_item1_image_url = results[4] : scn4_item1_image_url = formControls?.scn4_item1_image
                results[5] != ""? scn4_item2_image_url = results[5] : scn4_item2_image_url = formControls?.scn4_item2_image
                results[6] != ""? scn4_item3_image_url = results[6] : scn4_item3_image_url = formControls?.scn4_item3_image
                results[7] != ""? scn5_bg_image_url = results[7] : scn5_bg_image_url = formControls?.scn5_bg_image
                results[8] != ""? scn5_left_image_url = results[8] : scn5_left_image_url = formControls?.scn5_left_image


                let postData = {
                    ...formControls,
                    'scn1_bg_image':scn1_bg_image_url,
                    'scn3_bg_image':scn3_bg_image_url,
                    'scn2_bg_image':scn2_bg_image_url,
                    'scn4_bg_image':scn4_bg_image_url,
                    'scn4_item1_image':scn4_item1_image_url,
                    'scn4_item2_image':scn4_item2_image_url,
                    'scn4_item3_image':scn4_item3_image_url,
                    'scn5_bg_image':scn5_bg_image_url,
                    'scn5_left_image':scn5_left_image_url,
                }

                axios.defaults.withCredentials = true;
                await axios.post(`${baseUrl}/api/saveAbout`,postData).then((response) =>{
                    console.log(response)
                    setLoading(false)
                    toast.dismiss(loadingToast)
                    toast.success("Media updated Successfully");
                    setFormControls(response?.data?.data)  
                }).catch((error)=>{
                    console.log(error)
                    toast.dismiss(loadingToast)
                    toast.error(error?.message);
                    setLoading(false)
                });

            }).catch((e)=>{
                console.log(e)
                toast.error(e?.message);
            })
    }

    const fileUploadPromise = (imageFile:File|undefined|null)  => {
        return new Promise (async (resolve, reject) => {
            if(imageFile){
                    let uuid = require("uuid");
                    try{
                        let uploadTask = storage.ref(`/fileUploads/${uuid.v4()+"."+imageFile.name.split(".")[1]}`).put(imageFile);
                        uploadTask.then(uploadSnapshot => {
                            return uploadSnapshot.ref.getDownloadURL();
                        }).then(url => {
                            resolve(url);
                        }).catch(err => {
                            reject(err);
                        })
                    }catch(e){
                        console.log(e)
                        reject(e);

                    }
            }else{
                resolve("");
            }
        })
    }
    
  return (
    <>
        <Nav pageTitle="Edit About Screen"></Nav>
        <main className="p-6">
            <form onSubmit={(e)=>handleSubmit(e)} className="flex flex-col gap-6">

                {/* Section 1 */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Section 1</h4>

                        <div className="grid lg:grid-cols-2 gap-6">
                            <div>
                                <div className="flex flex-col gap-6">
                                    <div>
                                        
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="simpleinput"
                                        >
                                            Section 1 - Background Image
                                        </label>
                                        {
                                            formControls?.scn1_bg_image && <img src={formControls?.scn1_bg_image} className='w-[200px] block mb-2'/>
                                        }
                                        <input
                                            type="file"
                                            id="example-fileinput"
                                            className="form-input border"
                                            ref={scn1_bg_image_ref}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Section 1 - Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            required
                                            value={formControls?.scn1_title}
                                            onChange={(e)=> setFormControls({...formControls,scn1_title:e.target.value})}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 2 */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Section 2</h4>

                        <div className="grid lg:grid-cols-2 gap-6">
                            <div>
                                <div className="flex flex-col gap-6">
                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="simpleinput"
                                        >
                                            Section 2 - Background Image
                                        </label>
                                        {
                                            formControls?.scn2_bg_image && <img src={formControls?.scn2_bg_image} className='w-[200px] block mb-2'/>
                                        }
                                        <input
                                            type="file"
                                            id="example-fileinput"
                                            className="form-input border"
                                            ref={scn2_bg_image_ref}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Section 2 - Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            required
                                            value={formControls?.scn2_title}
                                            onChange={(e)=> setFormControls({...formControls,scn2_title:e.target.value})}
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-gray-600 mb-2" htmlFor="example-palaceholder">
                                            Section 2 - Description (Paragraph 1)
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            rows={5}
                                            required
                                            value={formControls?.scn2_description1}
                                            onChange={(e)=> setFormControls({...formControls,scn2_description1:e.target.value})}
                                        ></textarea>
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-palaceholder"
                                        >
                                            Section 2 - Description (Paragraph 2)
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            rows={5}
                                            required
                                            value={formControls?.scn2_description2}
                                            onChange={(e)=> setFormControls({...formControls,scn2_description2:e.target.value})}
                                        ></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 3 */}
                <div className="card tst">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Section 3</h4>

                        <div className="grid lg:grid-cols-2 gap-6">
                            <div>
                                <div className="flex flex-col gap-6">
                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="simpleinput"
                                        >
                                            Section 3 - Background Image
                                        </label>
                                        {
                                            formControls?.scn3_bg_image && <img src={formControls?.scn3_bg_image} className='w-[200px] block mb-2'/>
                                        }
                                        <input
                                            type="file"
                                            id="example-fileinput"
                                            className="form-input border"
                                            ref={scn3_bg_image_ref}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Section 3 - Section Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            required
                                            value={formControls?.scn3_title}
                                            onChange={(e)=> setFormControls({...formControls,scn3_title:e.target.value})}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-palaceholder"
                                        >
                                            Section 3 - Section description
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            rows={5}
                                            required
                                            value={formControls?.scn3_description}
                                            onChange={(e)=> setFormControls({...formControls,scn3_description:e.target.value})}
                                        ></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Section 4 */}
                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Section 4</h4>

                        <div className="grid lg:grid-cols-1 gap-6">
                            <div>
                                <div className="flex flex-col gap-6">
                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="simpleinput"
                                        >
                                            Section 4 - Background Image
                                        </label>
                                        {
                                            formControls?.scn4_bg_image && <img src={formControls?.scn4_bg_image} className='w-[200px] block mb-2'/>
                                        }
                                        <input
                                            type="file"
                                            id="example-fileinput"
                                            className="form-input border"
                                            ref={scn4_bg_image_ref}
                                        />
                                    </div>

                                    <div className='grid grid-cols-3 gap-6'>
                                        <div>
                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-email"
                                                >
                                                    Item 1 - Image
                                                </label>
                                                {
                                                    formControls?.scn4_item1_image && <img src={formControls?.scn4_item1_image} className='w-[200px] block mb-2'/>
                                                }
                                                <input
                                                    type="file"
                                                    id="example-fileinput"
                                                    className="form-input border"
                                                    ref={scn4_item1_image_ref}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-password"
                                                >
                                                    Item 1 Title
                                                </label>
                                                <input
                                                    type="text"
                                                    id="example-password"
                                                    className="form-input"
                                                    required
                                                    value={formControls?.scn4_item1_title}
                                                    onChange={(e)=> setFormControls({...formControls,scn4_item1_title:e.target.value})}
                                                />
                                            </div>

                                        </div>
                                        <div>
                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-email"
                                                >
                                                    Item 2 - Image
                                                </label>
                                                {
                                                    formControls?.scn4_item2_image && <img src={formControls?.scn4_item2_image} className='w-[200px] block mb-2'/>
                                                }
                                                <input
                                                    type="file"
                                                    id="example-fileinput"
                                                    className="form-input border"
                                                    ref={scn4_item2_image_ref}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-password"
                                                >
                                                    Item 2 Title
                                                </label>
                                                <input
                                                    type="text"
                                                    id="example-password"
                                                    className="form-input"
                                                    required
                                                    value={formControls?.scn4_item2_title}
                                                    onChange={(e)=> setFormControls({...formControls,scn4_item2_title:e.target.value})}
                                                />
                                            </div>

                                        </div>
                                        <div>
                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-email"
                                                >
                                                    Item 3 - Image
                                                </label>
                                                {
                                                    formControls?.scn4_item3_image && <img src={formControls?.scn4_item3_image} className='w-[200px] block mb-2'/>
                                                }
                                                <input
                                                    type="file"
                                                    id="example-fileinput"
                                                    className="form-input border"
                                                    ref={scn4_item3_image_ref}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-password"
                                                >
                                                    Item 3 Title
                                                </label>
                                                <input
                                                    type="text"
                                                    id="example-password"
                                                    className="form-input"
                                                    required
                                                    value={formControls?.scn4_item3_title}
                                                    onChange={(e)=> setFormControls({...formControls,scn4_item3_title:e.target.value})}
                                                />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="p-6">
                        <h4 className="card-title mb-4">Section 5</h4>

                        <div className="grid lg:grid-cols-2 gap-6">
                            <div>
                                <div className="flex flex-col gap-6">
                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="simpleinput"
                                        >
                                            Section 5 - Background Image
                                        </label>
                                        {
                                            formControls?.scn5_bg_image && <img src={formControls?.scn5_bg_image} className='w-[200px] block mb-2'/>
                                        }
                                        <input
                                            type="file"
                                            id="example-fileinput"
                                            className="form-input border"
                                            ref={scn5_bg_image_ref}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-email"
                                        >
                                            Section 5 - Left Image
                                        </label>
                                        {
                                            formControls?.scn5_left_image && <img src={formControls?.scn5_left_image} className='w-[200px] block mb-2'/>
                                        }
                                        <input
                                            type="file"
                                            id="example-fileinput"
                                            className="form-input border"
                                            ref={scn5_left_image_ref}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-password"
                                        >
                                            Section 5 - Section Title
                                        </label>
                                        <input
                                            type="text"
                                            id="example-password"
                                            className="form-input"
                                            required
                                            value={formControls?.scn5_title}
                                            onChange={(e)=> setFormControls({...formControls,scn5_title:e.target.value})}
                                        />
                                    </div>

                                    <div>
                                        <label
                                            className="block text-gray-600 mb-2"
                                            htmlFor="example-palaceholder"
                                        >
                                            Section 5 - Section description
                                        </label>
                                        <textarea
                                            className="form-input"
                                            id="example-textarea"
                                            rows={5}
                                            required
                                            value={formControls?.scn5_description}
                                            onChange={(e)=> setFormControls({...formControls,scn5_description:e.target.value})}
                                        ></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-span-2 '>
                    {
                        !loading?(
                            <button type="submit" className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Submit</button>
                        ):(
                            <button type="button" disabled className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Loading</button>
                        )
                    }                
                </div>  

            </form>
        </main>
    </>
  )
}
