import React,{useState,useRef,useEffect,} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';
import { Link } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { useParams } from 'react-router-dom';



type users = {
    'id': number,
    'email': string,
    'first_name': string,
    'last_name': string,
    'exp_date_m': string,
    'exp_date_y': string,
    'card_number': string,
    'cvv': string,
    'created_at'?: string
}

type PageVisits = {
  'id': number,
  'user_id': number,
  'screen_name': string,
  'start_time': string,
  'end_time': string

}

export default function CustomerPageVisits() {

  let {id} = useParams();

    let loadingToast : any;
    const [getLoading,setGetLoading] = useState(true);
    const [users, setUsers] = useState<users[]>([]);
    const [pageVisits, setPageVisits] = useState<PageVisits[]>([]);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [musicMetrics, setMusicMetrics] = useState<number>();
    const [gameMetrics, setGameMetrics] = useState<number>();
    const [musicUsage, setMusicUsageMetrics] = useState<any[]>([]);
    const [gameUsage, setGameUsageMetrics] = useState<any[]>([]);
    const tableRef = useRef(null);

    useEffect(()=> {
        const getScreenTime = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/media_pages_metric/${id}`).then((response) =>{
                console.log(response?.data?.data?.logins)
                
                setGetLoading(false)
                toast.dismiss(loadingToast)
                // setPageVisits(response?.data?.data?.screenTime)                
                setMusicMetrics(response.data.data?.music_visit);
                setGameMetrics(response.data.data?.game_visit);
                setMusicUsageMetrics(response.data.data?.music_time_spent);
                setGameUsageMetrics(response.data.data?.game_time_spent);
            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getScreenTime()
    },[id])

    const handleExportPDF = () => {
        const input : any = tableRef.current;
    
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(imgData, 'PNG', 0, 0, 210, 297);
            pdf.save('table.pdf');
          });
    };

  return (
    <>
        <Nav pageTitle="Users"></Nav>       
        <main className="p-6 space-y-6">

            {/* <div className="grid xl:grid-cols-2 grid-cols-1 gap-6">

                <div className="xl:col-span-2">
                    <div className="card">
                        <div className="p-6">
                            

                            <div className="flex items-center justify-between mb-6">
                                <h3 className="card-title">User Screen Time

                                </h3>

                                <div className='flex gap-10 items-center'>
                                    

                                    <div className=' relative'>
                                        <button data-fc-target="dropdown-target13" onClick={()=>setShowOptions(!showOptions)} data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                            <i className="mdi mdi-dots-vertical text-xl"></i>
                                        </button>
                                        {
                                            showOptions &&  <div id="dropdown-target13" className=" absolute top-10 right-0  bg-white shadow rounded border dark:border-slate-700 fc-dropdown-open:translate-y-0 translate-y-3 origin-center transition-all duration-300 py-2 dark:bg-gray-800 w-[200px]">
                                                <DownloadTableExcel
                                                    filename="Users table"
                                                    sheet="Users"
                                                    currentTableRef={tableRef.current}>
                                                    <button className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-stone-100 dark:hover:bg-slate-700 dark:hover:text-gray-200" >
                                                        Export to CSV
                                                    </button>
                                                </DownloadTableExcel>
                                                
                                                <button onClick={() =>handleExportPDF()} className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" >
                                                    Export to PDF
                                                </button>
                                                <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                                    Something else
                                                </a>
                                                <a className="flex items-center py-1.5 px-5 text-sm transition-all duration-300 bg-transparent text-gray-800 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-gray-300" href="javascript:void(0)">
                                                    Separated link
                                                </a>
                                            </div>
                                        }
                                    
                                    </div>
                                </div>
                                    
                            </div>

                            <div className="overflow-x-auto">
                                <div className="min-w-full inline-block align-middle">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Screen Name</th>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Started at</th>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Ended at</th>
                                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">

                                                {
                                                    pageVisits?.length > 0 && pageVisits?.map( (item,index) => <tr>
                                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">{index + 1}</th>
                                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.screen_name}</td>
                                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{moment(item.start_time).format('DD/MM/YYYY HH:mm')}</td>
                                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{moment(item.end_time).format('DD/MM/YYYY HH:mm')}</td>
                                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">
                                                            
                                                        </td>
                                                    </tr>)
                                                }
                                                

                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div> */}

            <div className="flex items-center justify-between mb-6">
                <h3 className="card-title">User Media Pages Metrics</h3>
            </div>

            <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
            <div className="card">
                <div className="p-6">
                    <div className="flex items-center justify-between mb-11">
                        <h4 className="card-title">Music Page Visits</h4>

                        <div className="z-10">
                            <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                <i className="mdi mdi-dots-vertical text-xl"></i>
                            </button>

                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div dir="ltr">
                            {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                        </div>

                        <div className="text-end">
                            <h2 className="text-3xl font-normal text-gray-800 mb-1"> {musicMetrics} </h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="p-6">
                    <div className="flex items-center justify-between mb-11">
                        <h4 className="card-title">Game Page Visits</h4>

                        <div className="z-10">
                            <button data-fc-target="dropdown-target1" data-fc-type="dropdown" type="button" data-fc-placement="bottom-end">
                                <i className="mdi mdi-dots-vertical text-xl"></i>
                            </button>

                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <div dir="ltr">
                            {/* <input data-plugin="knob" data-width="70" data-height="70" data-fgColor="#f05050 " data-bgColor="#F9B9B9" value="58" data-skin="tron" data-angleOffset="180" data-readOnly={true} data-thickness=".15" /> */}
                        </div>

                        <div className="text-end">
                            <h2 className="text-3xl font-normal text-gray-800 mb-1"> {gameMetrics} </h2>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="grid xl:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-6">
            <div className="overflow-x-auto card p-6">
                <div className="flex items-center justify-between mb-11">
                    <h4 className="card-title">Time spent on Musics</h4>
                </div>
                <div className="min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Music Id</th>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Music</th>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">total Time spent</th></tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                {
                                    musicUsage?.map((item,index) => (<tr>

                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">{index+1}</th>
                                        <th className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500">{item?.music}</th>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.music_name}</td>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.total_time_spent}</td>

                                            </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="overflow-x-auto card p-6">
                <div className="flex items-center justify-between mb-11">
                    <h4 className="card-title">Time spent on Games</h4>
                </div>
                <div className="min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">#</th>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">Game</th>
                                    <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">total Time spent</th></tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                {
                                    gameUsage?.map((item,index) => (<tr>

                                        <th scope="col" className="px-4 py-4 text-start text-sm font-medium text-gray-500">{index+1}</th>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.game_name}</td>
                                        <td className="px-4 py-4 whitespace-nowrap text-sm font-medium text-gray-500 ">{item?.total_time_spent}</td>

                                            </tr>))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>

        </main>
    </>
  )
}
