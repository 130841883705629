import React,{useState,useRef,useEffect} from 'react'
import Nav from '../Layout/Nav';
import toast from 'react-hot-toast';
import axios from 'axios'
import storage from '../Utils/firebase';
import { baseUrl } from '../Utils/app';

type FaqObject = {
    id?: null,
    title: string,
    description: string
} & {
    [key: string]: any;
};

export default function HelpForm() {

    const [faqs, setFaqs] = useState<FaqObject[]>([
        {
            // id: null,
            title:"",
            description:""
        }
    ]);

    const [loading,setLoading] = useState(false);
    const [getLoading,setGetLoading] = useState(true);

    let loadingToast : any;

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
            top: 0,
            behavior: 'smooth', // You can use 'auto' for an instant scroll
            });
        };
        scrollToTop();
    },[]);

    useEffect(()=>{
        const getFaqs = async () => {
            setGetLoading(true);
            loadingToast = toast.loading("Loading");

            await axios.get(`${baseUrl}/api/getFaq`).then((response) =>{
                console.log(response?.data)
                setGetLoading(false)
                toast.dismiss(loadingToast)
                setFaqs(response?.data?.data)                

            }).catch((error)=>{
                console.log(error)
                toast.dismiss(loadingToast)
                toast.error(error?.message);
                setGetLoading(false)
            });
        }

        getFaqs();
    },[]);


    const handleChange = (index:number, name:string, value:string) => {
        let updatedFaqs: FaqObject[] = [...faqs];
        updatedFaqs[index][name] = value;
        setFaqs(updatedFaqs);
    };

    const addFaq = ()=>{
        setFaqs([...faqs,{
            id:null,
            title: "",
            description: ""
        }])
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        loadingToast = toast.loading("Loading");

        await axios.post(`${baseUrl}/api/saveFaq`,{"faq":faqs}).then((response) =>{
            console.log(response)
            setLoading(false)
            toast.dismiss(loadingToast)
            toast.success("Media updated Successfully");
        }).catch((error)=>{
            console.log(error)
            toast.dismiss(loadingToast)
            toast.error(error?.message);
            setLoading(false)
        });
    }

    return (
        <>
            <Nav pageTitle="Edit Help Screen"></Nav>
            <main className="p-6">
                <div className=' mb-5'>
                    <button type="button" onClick={() => addFaq()} className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white">Add Faq</button>
                </div>
                <form onSubmit={(e)=>handleSubmit(e)} className="flex flex-col gap-6">

                    {/* FAQ Cards 1 */}
                    {
                        faqs?.length > 0 && faqs?.map((item,index)=><div className="card">
                            <div className="p-6">
                                <h4 className="card-title mb-4">FAQ {index+1}</h4>

                                <div className=" gap-6">
                                    <div>
                                        <div className="grid lg:grid-cols-1 gap-6">
                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-password"
                                                >
                                                    FAQ {index+1} - Title
                                                </label>
                                                <input
                                                    type="text"
                                                    id="example-password"
                                                    className="form-input"
                                                    value={item?.title}
                                                    onChange={(e)=>handleChange(index,'title',e.target.value)}
                                                />
                                            </div>

                                            <div>
                                                <label
                                                    className="block text-gray-600 mb-2"
                                                    htmlFor="example-palaceholder"
                                                >
                                                    FAQ {index+1} - Description
                                                </label>
                                                <textarea
                                                    className="form-input"
                                                    id="example-textarea"
                                                    rows={2}
                                                    value={item?.description}
                                                    onChange={(e)=>handleChange(index,'description',e.target.value)}
                                                ></textarea>
                                            </div>

                                            <button type="button" onClick={()=>setFaqs([...faqs?.filter((item,faqIndex) => index != faqIndex)])} className="btn rounded-full border border-danger text-danger hover:bg-danger hover:text-white">Delete</button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }
                    <div className=' mb-5'>
                        {
                            !loading?(
                                <button type="submit" className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Submit</button>
                            ):(
                                <button type="button" disabled className="btn rounded-full border border-primary text-primary hover:bg-primary hover:text-white float-right">Loading</button>
                            )
                        }  
                    </div>
                </form >
            </main>
        </>
    )
}
