import React, { useReducer, useContext, createContext } from 'react';

// Define the initial state

const initialState = {
  isAuth: false,
  userId: null,
  user: null,
  token: null
};

// Create the authentication reducer
function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, isAuth: true, user:null, token:null };
    case 'LOGOUT':
      localStorage.setItem("USER_DATA",null);
      localStorage.setItem("USER_TOKEN",null);
      return { ...state, isAuth: false, user:null, token:null };
      
    case 'LOGIN-USER':
      localStorage.setItem("USER_DATA",JSON.stringify(action?.payload?.user));
      localStorage.setItem("USER_TOKEN",JSON.stringify(action?.payload?.token));
      return { ...state, isAuth: true, user:action?.payload?.user, token: action?.payload?.token};
    case 'RESTORE':
      return { ...state, isAuth: true, user:action?.payload?.user, token: action?.payload?.token};
    default:
      return state;
  }
}

// Create the authentication context
const AuthContext = createContext();

// Create the AuthProvider component
export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
}

// Custom hook for using the auth context
export function useAuth() {
  return useContext(AuthContext);
}
